import React from "react";
import styled from "styled-components";
import { publicImage } from "../../shared/util";

const OnBoarding = ({ setOnboardingModalState, onboardingModalState, setOnBoardingLocalStorage }) => {
  const handleNextClick = () => {
    if (onboardingModalState !== 4) {
      setOnboardingModalState(onboardingModalState + 1);
    } else {
      setOnboardingModalState(0);
      setOnBoardingLocalStorage(true);
    }
  };

  const handleClickSkipButton = () => {
    setOnBoardingLocalStorage(true)
    setOnboardingModalState(0)
  }

  const handleFirstModalClick = () => {
    setOnboardingModalState(1);
  };
  const handleSecondtModalClick = () => {
    setOnboardingModalState(2);
  };
  const handleThirdtModalClick = () => {
    setOnboardingModalState(3);
  };
  const handleForthModalClick = () => {
    setOnboardingModalState(4);
  };

  return !onboardingModalState ? null : onboardingModalState === 1 ? (
      <OnBoardingModal1>
        <Arrow1 src={publicImage('/onboardingarrow1.svg')} alt="arrow" />
        <ModalNext>
          <ModalNumber>
            01
            <PrevNumber />
            04
          </ModalNumber>
          <ModalFlex>
            <img onClick={handleFirstModalClick} src={publicImage('/fillmodal.svg')} alt="" />
            <img
              onClick={handleSecondtModalClick}
              src={publicImage('/emptymodal.svg')}
              alt=""
            />
            <img
              onClick={handleThirdtModalClick}
              src={publicImage('/emptymodal.svg')}
              alt=""
            />
            <img
              onClick={handleForthModalClick}
              src={publicImage('/emptymodal.svg')}
              alt=""
            />
          </ModalFlex>
        </ModalNext>
        <ModalText>
          Выберите ИИ-сэмпл в&nbsp;народном стиле, чтобы начать создавать трек
        </ModalText>
        <ModalButtons>
          <ModalPassText onClick={handleClickSkipButton}>
            Пропустить
          </ModalPassText>
          <ModalNextButton onClick={handleNextClick}>Далее</ModalNextButton>
        </ModalButtons>
      </OnBoardingModal1>
  ) : onboardingModalState === 2 ? (
    <OnBoardingModal2>
      <Arrow2 src={publicImage("/onboardingarrow2.svg")} alt="arrow" />
      <ModalNext>
        <ModalNumber>
          02
          <PrevNumber />
          04
        </ModalNumber>
        <ModalFlex>
          <img
            onClick={handleFirstModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
          <img
            onClick={handleSecondtModalClick}
            src={publicImage("/fillmodal.svg")}
            alt=""
          />
          <img
            onClick={handleThirdtModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
          <img
            onClick={handleForthModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
        </ModalFlex>
      </ModalNext>
      <ModalText>
        Нажимайте на&nbsp;инструменты и&nbsp;добавляйте их&nbsp;в&nbsp;трек. Если вы&nbsp;хотите убрать
        инструмент&nbsp;&mdash; нажмите на&nbsp;него повторно.
      </ModalText>
      <ModalButtons>
        <ModalPassText onClick={handleClickSkipButton}>
          Пропустить
        </ModalPassText>
        <ModalNextButton onClick={handleNextClick}>Далее</ModalNextButton>
      </ModalButtons>
    </OnBoardingModal2>
  ) : onboardingModalState === 3 ? (
    <OnBoardingModal3>
      <Arrow1 src={publicImage("/onboardingarrow1.svg")} alt="arrow" />
      <ModalNext>
        <ModalNumber>
          03
          <PrevNumber />
          04
        </ModalNumber>
        <ModalFlex>
          <img
            onClick={handleFirstModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
          <img
            onClick={handleSecondtModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
          <img
            onClick={handleThirdtModalClick}
            src={publicImage("/fillmodal.svg")}
            alt=""
          />
          <img
            onClick={handleForthModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
        </ModalFlex>
      </ModalNext>
      <ModalText>
        Вы&nbsp;можете экспериментировать с&nbsp;другими ИИ-сэмплами&nbsp;&mdash; просто нажмите
        кнопку с&nbsp;названием мелодии
      </ModalText>
      <ModalButtons>
        <ModalPassText onClick={handleClickSkipButton}>
          Пропустить
        </ModalPassText>
        <ModalNextButton onClick={handleNextClick}>Далее</ModalNextButton>
      </ModalButtons>
    </OnBoardingModal3>
  ) : (
    <OnBoardingModal4>
      <Arrow3 src={publicImage("/onboardingarrow2.svg")} alt="arrow" />
      <ModalNext>
        <ModalNumber>
          04
          <PrevNumber />
          04
        </ModalNumber>
        <ModalFlex>
          <img
            onClick={handleFirstModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
          <img
            onClick={handleSecondtModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
          <img
            onClick={handleThirdtModalClick}
            src={publicImage("/emptymodal.svg")}
            alt=""
          />
          <img
            onClick={handleForthModalClick}
            src={publicImage("/fillmodal.svg")}
            alt=""
          />
        </ModalFlex>
      </ModalNext>
      <ModalText>
        Чтобы сохранить созданный вами трек, нажмите кнопку &laquo;Завершить&raquo;.
        Сохранится мелодия и&nbsp;инструменты, которые активны на&nbsp;экране.
      </ModalText>
      <ModalButtons>
        <ModalPassText onClick={handleClickSkipButton}>
          Пропустить
        </ModalPassText>
        <ModalNextButton onClick={handleNextClick}>Ок</ModalNextButton>
      </ModalButtons>
    </OnBoardingModal4>
  );
};
export default OnBoarding;

const ModalNumber = styled.div`
  font-family: 'SB Sans Text';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: var(--4, #209f6d);
  display: flex;
  align-items: center;
`;
const ModalFlex = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
  cursor: pointer;
`;
const ModalText = styled.div`
  font-family: 'SB Sans Text';
  font-size: 15px;
  font-weight: 400;
`;

const ModalNext = styled.div``;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ModalPassText = styled.span`
  font-family: 'SB Sans Display';
  font-size: 16px;
  font-weight: 300;
  color: #209f6d;
  cursor: pointer;
`;
const ModalNextButton = styled.button`
  width: 145px;
  height: 44px;
  padding: 12px 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #209f6d;
  background-color: transparent;
  font-family: 'SB Sans Display';
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  z-index: 2;
  border: 1px solid var(--4, #209f6d);
  border-radius: 40px;
`;
const PrevNumber = styled.div`
  border-bottom: 1px solid var(--4, #209f6d);
  width: 38px;
  margin: 0 5px;
`;

const Arrow1 = styled.img`
  position: absolute;
  right: 55px;
  top: -43px;
`;

const OnBoardingModal1 = styled.div`
  position: absolute;
  top: 170px;
  left: 0;
  background-color: #dafdea;
  z-index: 10;
  padding: 14px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 10px;
  @media (min-width: 375px) {
    margin: 0 30px;
    top: 188px;
  }
  @media (min-width: 768px) {
    margin: 0 213px;
    top: 255px;
    width: 315px;
  }
  @media (min-width: 1024px) {
    margin: 0 354px;
    left: 50px;
  }
  @media (min-width: 1400px) {
    margin: 0 386px 0 739px;
    top: 335px;
  }
`;
const OnBoardingModal2 = styled.div`
  position: absolute;
  top: 75px;
  left: 0;
  background-color: #dafdea;
  z-index: 10;
  padding: 14px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 10px;
  @media (min-width: 375px) {
    margin: 0 30px;
    top: 190px;
  }
  @media (min-width: 768px) {
    margin: 0 213px;
    top: 375px;
    width: 315px;
  }
  @media (min-width: 1024px) {
    margin: 0 354px;
    top: 20px;
    left: -100px;
  }
  @media (min-width: 1400px) {
    margin: 0 386px 0 739px;
    top: 240px;
  }
`;
const OnBoardingModal3 = styled.div`
  position: absolute;
  top: 170px;
  left: 0;
  background-color: #dafdea;
  z-index: 10;
  padding: 14px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 10px;
  @media (min-width: 375px) {
    margin: 0 30px;
    top: 188px;
  }
  @media (min-width: 768px) {
    margin: 0 213px;
    top: 255px;
    width: 315px;
  }
  @media (min-width: 1024px) {
    margin: 0 354px;
  }
  @media (min-width: 1400px) {
    margin: 0 386px 0 739px;
    top: 335px;
  }
`;
const OnBoardingModal4 = styled.div`
  position: absolute;
  bottom: 160px;
  left: 0;
  background-color: #dafdea;
  z-index: 10;
  padding: 14px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 10px;
  @media (min-width: 375px) {
    margin: 0 30px;
    bottom: 190px;
  }
  @media (min-width: 768px) {
    margin: 0 213px;
    width: 315px;
    bottom: 200px;
  }
  @media (min-width: 1024px) {
    margin: 0 354px;
  }
  @media (min-width: 1400px) {
    margin: 0 386px 0 739px;
    bottom: 220px;
    left: -200px;
  }
`;

const Arrow2 = styled.img`
  position: absolute;
  right: 55px;
  top: 180px;
  z-index: 1;
  @media (max-width: 375px) {
    transform: rotate(-7deg);
  }
`;

const Arrow3 = styled.img`
  position: absolute;
  right: 55px;
  top: 230px;
`;
