import React from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { publicImage } from "../../../shared/util";

const PlayButton = () => {
  return (
    <PlayVideoButton>
      <PlayButtonBackground></PlayButtonBackground>
      <VideoSpan>Видео</VideoSpan>
    </PlayVideoButton>
  );
};
PlayButton.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default PlayButton;

const PlayVideoButton = styled.button`
  width: 105px;
  height: 48px;
  position: relative;
  left: 0;
  right: 0;
  border-radius: 105px;
  background: rgba(255, 255, 255, 0.30);
  backdrop-filter: blur(3px);
  display: flex;
  font-size: 12px;
  align-items: center;
  border: none;
  justify-content: space-between;
  z-index: 1;
  cursor: pointer;
  @media (min-width: 768px) {
    width: 180px;
    height: 84px;
    border-radius: 180px;
    font-size: 18px;
  }
`;

const PlayButtonBackground = styled.div`
  height: 41px;
  width: 41px;
  border-radius: 41px;
  background: url(${publicImage("/playbuttonbg.svg")}) center;
  margin-left: 4px;
  background-size: 105%;

  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
    margin-left: 7px;
  }
`;

const VideoSpan = styled.span`
  margin-right: 16px;
  font-size: 12px;
  font-family: "SB Sans Display";
  font-weight: 300;
  color: #FFFFFF;
  @media (min-width: 768px) {
    margin-right: 30px;
    font-size: 18px;
  }
`;
