import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { VKShareButton } from "next-share";
import styled from "styled-components";
import Header from "../../WelcomePage/components/Header";
import { Link, useSearchParams } from "react-router-dom";
import ReactHowler from "react-howler";
import PlayerProgressbar from "./PlayerProgressbar";
import {copyText, publicImage} from '../../../shared/util';
import { SongEncoder } from '../../../utils/songEncoder'
import { urlSearchParam } from '../../../config/variables'
import { songs } from '../../../config/songs'
import { instrumentsMap } from '../../../config/instruments'

const ResultPageSection1 = ({ setOpen }) => {
  const handleArrow2Click = () => {
    const element = document.getElementById("resultsection2");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const currentSongRef = useRef(null);
  const talyanSongRef = useRef(null);
  const jaleykaSongRef = useRef(null);
  const gusliSongRef = useRef(null);
  const dungurSongRef = useRef(null);
  const varganSongRef = useRef(null);
  const pangurSongRef = useRef(null);

  const copyTextareaRef = useRef(null);

  const [play, setPlay] = useState(false);
  const [talyanPlay, setTalyanPlay] = useState(false);
  const [jaleykaPlay, setJaleykaPlay] = useState(false);
  const [gusliPlay, setGusliPlay] = useState(false);
  const [dungurPlay, setDungurPlay] = useState(false);
  const [varganPlay, setVarganPlay] = useState(false);
  const [pangurPlay, setPandurPlay] = useState(false);

  const [searchParams] = useSearchParams();
  const encodedSong = searchParams.get("song") || "";
  const location = window.location.href;

  const [ isCopied, setIsCopied ] = useState(false)
  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const { song: currentSong, instruments } = useMemo(() => {
    return SongEncoder.decode(searchParams.get(urlSearchParam.song))
  }, [encodedSong])

  const [position, setPosition] = useState(0);

  const handlePlayClick = () => {
    setPlay(!play);

    const rest = encodedSong.slice(1);
    if (rest.includes(instrumentsMap.talyan.encoded)) {
      setTalyanPlay(!talyanPlay);
    }
    if (rest.includes(instrumentsMap.jaleyka.encoded)) {
      setJaleykaPlay(!jaleykaPlay);
    }
    if (rest.includes(instrumentsMap.gusli.encoded)) {
      setGusliPlay(!gusliPlay);
    }
    if (rest.includes(instrumentsMap.dungur.encoded)) {
      setDungurPlay(!dungurPlay);
    }
    if (rest.includes(instrumentsMap.vargan.encoded)) {
      setVarganPlay(!varganPlay);
    }
    if (rest.includes(instrumentsMap.pangur.encoded)) {
      setPandurPlay(!pangurPlay);
    }
  };

  const updatePosition = () => {
    setPosition(
      currentSongRef.current.seek() / currentSongRef.current.duration()
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updatePosition();
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, [position]);

  const handleBackClick = useCallback(() => {
    const newSeek = Math.max(currentSongRef.current.seek() - 15, 0);
    currentSongRef.current.seek(newSeek);
    talyanSongRef.current.seek(newSeek);
    jaleykaSongRef.current.seek(newSeek);
    gusliSongRef.current.seek(newSeek);
    dungurSongRef.current.seek(newSeek);
    varganSongRef.current.seek(newSeek);
    pangurSongRef.current.seek(newSeek);
  }, []);

  const handleForwardClick = () => {
    const newSeek = Math.min(
      currentSongRef.current.seek() + 15,
      currentSongRef.current.duration()
    );

    currentSongRef.current.seek(newSeek);
    talyanSongRef.current.seek(newSeek);
    jaleykaSongRef.current.seek(newSeek);
    gusliSongRef.current.seek(newSeek);
    dungurSongRef.current.seek(newSeek);
    varganSongRef.current.seek(newSeek);
    pangurSongRef.current.seek(newSeek);
  };

  const currentSongObject = useMemo(() => {
    return songs.find((song) => song.decodedName === encodedSong[0]);
  }, [encodedSong, songs]);

  const getInstrumentAudio = (instrument) => {
    const { id } = currentSongObject;
    if (!["volga", "gorniy", "motiv"].includes(id)) {
      return `https://sber.cdnvideo.ru/common/video/ravnina${instrument}.mp3`;
    }
    return `https://sber.cdnvideo.ru/common/video/${id}${instrument}.mp3`;
  };

  return (
    <Container height={window.innerHeight+'px'}>
      <Header toggleOpen={setOpen} />
      <ResultpageSection1Wrapper>
        <GetBack to={"/create"}>
          <BackArrow src={publicImage("/backarrow.svg")} alt="Вернуться" />
          <ReturnBackText>Вернуться</ReturnBackText>
        </GetBack>
        <ContentWrapper>
          <ContainerForMedia>
            <ResultPageSection1Title>
              Трек
              <GreenH1> «{currentSong.name}» </GreenH1>
              готов
            </ResultPageSection1Title>
            <ShareVKArticle>
            <span onClick={() => copyText(location, copyTextareaRef?.current, {
              onSuccess: () => {
                setIsCopied(true)
                clearTimeout(timeoutRef.current)
                timeoutRef.current = setTimeout(() => {
                  setIsCopied(false)
                }, 1500)
              }
            })}>
            Скопируй
            <IsCopiedBar theme={{ isOpaque: isCopied }}>
              <IsCopiedBarRelative>
                Ссылка скопирована
                <IsCopiedIcon />
              </IsCopiedBarRelative>
            </IsCopiedBar>
            </span> ссылку на трек или поделись им с друзьями VK

            </ShareVKArticle>
          </ContainerForMedia>
          <ResultPagePlayer>
            <PlayerProgressbar
              position={position}
              duration={currentSongRef.current?.duration()}
            />
            <ResultPagePlayerIcons>
              <StyledImage
                onClick={handleBackClick}
                src={publicImage("/back15sec.svg")}
              />
              <div onClick={handlePlayClick}>
                {play ? (
                  <PauseIcon src={publicImage("/pause.svg")} />
                ) : (
                  <PauseIcon src={publicImage("/play.svg")} />
                )}
              </div>
              <StyledImage
                onClick={handleForwardClick}
                src={publicImage("/forward15sec.svg")}
              />
            </ResultPagePlayerIcons>

            {instruments.length
              ? <InstrumentsContainer>
                <InstrumentsTitle>Инструменты в треке:</InstrumentsTitle>
                <InstrumentsList>{instruments.map(it => it.name).join(', ')}</InstrumentsList>
              </InstrumentsContainer>
              : null}

            <Section1Buttons>
              <VKShareButton title={"Звуки России"} url={location}>
                <ShareButton>
                  Поделиться
                  <StyledImage src={publicImage("/share.svg")} />
                </ShareButton>
              </VKShareButton>

              <LinkText to={"/create"}>
                <CreateOneMoreButton>Создать ещё раз</CreateOneMoreButton>
              </LinkText>
            </Section1Buttons>
            <MoreInfoAboutProject>
              <InfoLink>Узнайте о проекте</InfoLink>
              <StyledImage
                onClick={handleArrow2Click}
                src={publicImage("/arrow.svg")}
                alt="Узнайте больше"
              />
            </MoreInfoAboutProject>
          </ResultPagePlayer>
        </ContentWrapper>
      </ResultpageSection1Wrapper>
      <>
        <ReactHowler src={currentSong?.url} playing={play} ref={currentSongRef} />

        <ReactHowler
          src={getInstrumentAudio("garmun")}
          playing={talyanPlay}
          ref={talyanSongRef}
        />
        <ReactHowler
          src={getInstrumentAudio("jaleika")}
          playing={jaleykaPlay}
          ref={jaleykaSongRef}
        />
        <ReactHowler
          src={getInstrumentAudio("gusli")}
          playing={gusliPlay}
          ref={gusliSongRef}
        />
        <ReactHowler
          src={getInstrumentAudio("dungur")}
          playing={dungurPlay}
          ref={dungurSongRef}
        />
        <ReactHowler
          src={getInstrumentAudio("vargan")}
          playing={varganPlay}
          ref={varganSongRef}
        />
        <ReactHowler
          src={getInstrumentAudio("pandur")}
          playing={pangurPlay}
          ref={pangurSongRef}
        />
      </>
      <Textarea ref={copyTextareaRef}>{location}</Textarea>
    </Container>
  );
};

export default ResultPageSection1;

const ResultpageSection1Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  
  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0 40px;
  }
  @media (min-width: 1400px) {
    padding: 0 180px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //height: 100%;
  flex: 1 0 0;
`

const GetBack = styled(Link)`
  display: flex;
  gap: 5px;
  margin-top: 3.5vh;
  margin-bottom: 15px;
  text-decoration: none;
  cursor: pointer;
  @media (min-width: 375px) {
    margin-bottom: 25px;
  }
  @media (min-width: 768px) {
    margin-top: 1vh;
  }
`;
const BackArrow = styled.img`
  display: block;
`;
const ResultPageSection1Title = styled.h1`
  color: white;
  font-family: "SB Sans Display";
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 1.7vh;
  @media (min-width: 768px) {
    font-size: 56px;
  }
  @media (min-width: 1400px) {
    text-align: center;
  }
`;
const GreenH1 = styled.span`
  color: #00D900;
`;
const ShareVKArticle = styled.div`
  font-family: "SB Sans Text";
  font-size: 15px;
  font-style: normal;
  color: white;
  
  span {
    position: relative;
    cursor: pointer;
    text-decoration: underline;
  }
  
  @media (min-width: 768px) {
    font-size: 26px;
  }
  @media (min-width: 1400px) {
    text-align: center;
  }
`;

const IsCopiedBar = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: fit-content;
  opacity: ${props => props.theme.isOpaque ? 1 : 0};
  transition: opacity .4s ease;
  transform: translate(0, 8px);
  color: black;
  @media (min-width: 1440px) {
    left: 50%;
    transform: translate(-50%, 16px);
  }
`

const IsCopiedBarRelative = styled.div`
  filter: drop-shadow(8px 8px 24px rgba(0, 0, 0, 0.08));
  position: relative;
  padding: 8px 12px;
  background-color: #DAFDEA;
  border-radius: 8px;
  font-family: 'SB Sans Display';
  font-size: 12px;
  line-height: 130%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  @media (min-width: 768px) {
    padding: 17.5px 16px;
    font-size: 16px;
  }

  &::after {
    content: '';
    position: absolute;
    top: -6px;
    left: 18%;
    width: 16px;
    height: 6px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(180deg);
    background-image: url(${publicImage('/copy-bar/crutch-triangle-downwards-white.svg')});
    @media (min-width: 1440px) {
      background-image: url(${publicImage('/copy-bar/crutch-triangle-downwards-grey.svg')});
      top: -10px;
      left: 50%;
      width: 20px;
      height: 10px;
      transform: rotate(180deg) translate(0, 0);
    }
  }
`

const IsCopiedIcon = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${publicImage('/copy-bar/icon-checkmark-uneven-black.svg')});
  margin-left: 8px;
`

const ResultPagePlayer = styled.div`
  display: flex;
  flex-direction: column;
  //position: absolute;
  //bottom: 40px;
  margin-top: 20px;
  margin-bottom: 40px;
  //left: 50%;
  //margin-left: -140px;
  align-items: center;
  @media (min-width: 375px) {
    //margin-left: -157px;
  }
  @media (min-width: 768px) {
    //margin-left: -341px;
  }
  @media (min-width: 1024px) {
  }
`;

const ResultPagePlayerIcons = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 25px;
  @media (min-width: 1400px) {
    margin-top: 20px;
  }
`;
const StyledImage = styled.img`
  display: block;
  cursor: pointer;
`;

const InstrumentsContainer = styled.div`
  margin: 20px 0 20px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 6px;
  font-family: 'SB Sans Display';
  font-weight: 400;
  line-height: normal;
  font-size: 10px;
  color: white;
  text-align: center;
  
  @media (min-width: 375px) {
    margin: 24px 0 30px;
  }
  @media (min-width: 768px) {
    margin: 30px 0 36px;
    grid-gap: 12px;
    font-size: 18px;
    
  }
`
const InstrumentsTitle = styled.div`
`
const InstrumentsList = styled.div`
  opacity: 0.7;
`

const ShareButton = styled.button`
  width: 280px;
  height: 43px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: url(${publicImage("/btnellipse.svg")}) center no-repeat;
  background-size: fill;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(3px);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 12px;
  font-family: "SB Sans Display";
  font-size: 14px;
  font-weight: 300;
  @media (min-width: 375px) {
    width: 315px;
  }
  @media (min-width: 768px) {
    font-size: 24px;
    height: 60px;
    width: 400px;
    font-size: 24px;
    height: 60px;
  }
`;
const CreateOneMoreButton = styled.button`
  width: 280px;
  height: 43px;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid white;
  color: white;
  font-family: SB Sans Display;
  font-size: 16px;
  font-weight: 300;
  @media (min-width: 375px) {
    width: 315px;
  }
  @media (min-width: 768px) {
    font-size: 24px;
    height: 60px;
    width: 400px;
  }
`;
const MoreInfoAboutProject = styled.div`
  margin: 60px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;
const InfoLink = styled.span`
  color: #8dff57;
  font-family: SB Sans Display;
  font-size: 12px;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const ReturnBackText = styled.span`
  color: #00d900;
  font-family: "SB Sans Display";
  line-height: "110%";
  font-size: 12px;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const Section1Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContainerForMedia = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  min-height: ${({ height }) => height || '100vh'};
`;

const LinkText = styled(Link)`
  text-decoration: none;
`;

const PauseIcon = styled.img`
  cursor: pointer;
  width: 33px;
  border-radius: 20px;
`;

const Textarea = styled.textarea`
  opacity: 0;
  overflow: hidden;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
`
