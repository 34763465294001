import React from "react";
import styled from "styled-components";
import { publicImage } from "../../shared/util";

const PlugPage = () => {
  return (
    <Container>
      <Wrapper>
        <IEImage src={publicImage("explorer.svg")} alt="explorer" />
        <Article>
          Пожалуйста, воспользуйтесь другим браузером для перехода на страницу
        </Article>
        <Text>Поддерживаемые браузеры:</Text>
        <BrausersList>
          Chrome, Safari, Yandex, Firefox, Edge, Opera
        </BrausersList>
      </Wrapper>
      <Container1>
        <GreenEllipse src={publicImage("/greenellipse.svg")} alt="" />
      </Container1>
      <Container2>
        <BlueEllipse src={publicImage("/blueellipse.svg")} alt="" />
      </Container2>
    </Container>
  );
};

export default PlugPage;

const Container = styled.div`
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #000 9.06%, #333f48 100%);
`;
const IEImage = styled.img`
  display: block;
`;

const Article = styled.div`
  color: var(--primary-white, #fff);
  text-align: center;
  font-family: SB Sans Text;
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 32px;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    margin-bottom: 40px;
    font-size: 32px;
  }
`;
const Text = styled.span`
  color: var(--primary-white, #fff);
  text-align: center;
  font-family: SB Sans Text;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  opacity: 0.6;
  margin-bottom: 8px;
  @media (min-width: 768px) {
    margin-bottom: 16px;
    font-size: 20px;
  }
`;
const BrausersList = styled.div`
  color: var(--primary-white, #fff);
  text-align: center;
  font-family: SB Sans Text;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  font-size: 20px;
`;

const GreenEllipse = styled.img`
  width: 100%;
  height: 100%;
`;

const BlueEllipse = styled.img`
  width: 100%;
  height: 100%;
`;

const Container1 = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const Container2 = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 140px 16px;
  @media (min-width: 375px) {
    margin: 187px 16px;
  }
  @media (min-width: 768px) {
    margin: 332px 56px;
  }
  @media (min-width: 1400px) {
    margin: 206px 386px;
  }
`;
