import React, { useRef, useEffect } from "react";
import styled from "styled-components";

export default function SectionBGVideo() {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });

  return <Video src="https://sber.cdnvideo.ru/common/video/podlojka.mp4" ref={videoRef} loop autoPlay muted playsInline />;
}

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: black;
`;
