import React from "react";
import styled from "styled-components";
import { publicImage } from "./util";

const CookiesModal = ({ closeCookieModal }) => {
  return (
    <ModalContainer>
      <ModalText>
        ПАО Сбербанк использует cookie (файлы c&nbsp;данными о&nbsp;прошлых посещениях
        сайта) для персонализации сервисов и&nbsp;удобства пользователей. Сбербанк
        серьезно относится к&nbsp;защите персональных данных&nbsp;&mdash; ознакомьтесь&nbsp;с
        <Politics
          href={"https://www.sberbank.ru/privacy/cookies"}
          target="_blank">
          {" "}
          условиями и принципами их обработки.
        </Politics>{" "}
        Вы можете запретить сохранение cookie в настройках своего браузера.
      </ModalText>
      <ModalButton onClick={closeCookieModal}>Подтверждаю</ModalButton>
    </ModalContainer>
  );
};

export default CookiesModal;

const ModalContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-radius: 24px 24px 0px 0px;
  background-color: #333f48;
  z-index: 10;
  @media (min-width: 375px) {
    padding: 24px;
  }
  @media (min-width: 768px) {
    width: calc(100vw - 56px * 2);
    max-width: 1224px;
    flex-direction: row;
    bottom: 24px;
    border-radius: 24px;
  }
  @media (min-width: 1023px) {
    bottom: 28px;
    gap: 24px;
  }
`;

const ModalButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.3);
  background: url(${publicImage("/btnellipse.svg")}) center no-repeat;
  background-size: fill;
  height: 46px;
  line-height: 100%;
  color: #fff;
  text-align: center;
  font-family: SB Sans Display;
  font-size: 14px;
  font-weight: 300;
  padding: 0 30px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ModalText = styled.div`
  color: white;
  font-family: SB Sans Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
`;

const Politics = styled.a`
  color: #16a130;
  cursor: pointer;
  text-decoration: none;
`;
