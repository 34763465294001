import styled from "styled-components";
import { publicImage } from "../../shared/util";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useEffect, useRef } from "react";

function useClickOutside(ref, callback) {
  return ({ target }) => {
    if (ref.current && !ref.current.contains(target)) {
      callback();
    }
  }
}

export default function Popup({ text, video, onClose }) {
  const scrollElement = useRef(null);
  function onClick() {
    enableBodyScroll(scrollElement.current);
    onClose();
  }

  const popupRef = useRef(null);

  const clickOutsideListener = useClickOutside(popupRef, onClick);

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', clickOutsideListener);
    })
    disableBodyScroll(scrollElement.current, {
      allowTouchMove: (el) => el.closest('.bsl-ignore') !== null,
    });
  }, [clickOutsideListener]);

  useEffect(() => () => {
    document.removeEventListener('click', clickOutsideListener)
  }, [clickOutsideListener]);

  return (
    <PopupBg>
      <CloseButton onClick={onClick}>
        <img src={publicImage("/closeicon.svg")} alt="close" />
      </CloseButton>
      <PopupContent className="bsl-ignore" ref={popupRef}>
        <Video src={video} controls playsInline  />
        <PopupText
          ref={scrollElement}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </PopupContent>
    </PopupBg>
  );
}

export const PopupBg = styled.div`
  box-sizing: border-box;
  height: 100vh;
  height: 100dvh;
  justify-content: center;
  width: 100vw;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 50px;
  display: flex;
  overflow-y: auto;
  z-index: 100;
  @media (max-width: 1023px) {
    padding: 40px 38px;
  }
  @media (max-width: 767px) {
    padding: 20px 30px;
  }
`;

const PopupContent = styled.div`
  flex-grow: 1;
  background-color: #dafdea;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  border: none;
  outline: none;
  max-width: 688px;
  align-self: flex-start;
  box-sizing: border-box;
  background-clip: padding-box;
  margin: 0;
  padding: 0;
  transform: translateZ(0);

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const Video = styled.video`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  outline: none;
  border-radius: inherit;
  background-color: black;
  border: none;
  margin: 0;
  padding: 0;
  transform: translateZ(0);

`;

const PopupText = styled.div`
  padding: 40px 40px 78px;
  font-size: 14px;
  @media (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  @media (max-width: 767px) {
    top: 10px;
    right: 0;
  }
`;
