import React from "react";
import styled from "styled-components";
import SmallButton from "../../../shared/SmallButton";
import Flex from "../../../shared/Flex";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import { Link } from "react-router-dom";
import { publicImage } from "../../../shared/util";

const Header = ({ toggleOpen }) => {
  return (
    <>
      <HeaderWrapper>
        <HeaderLargeContainer>
          <Link to={"http://www.sberbank.ru/ru/person"} target="_blank">
            <StyledLogo src={publicImage("/sber_logo.svg")} alt="Сбербанк" />
          </Link>
          <Flex gap="40px">
            <HeaderVideoButton onClick={toggleOpen}>Видео</HeaderVideoButton>
            <Link to={"/create"}>
              <SmallButton>Создать трек</SmallButton>
            </Link>
            <NavbarLink to={"/history"}>
              <HeaderHistoryButton>Как создавался проект</HeaderHistoryButton>
            </NavbarLink>
          </Flex>
          <Flex width="150px">
            <Link to={"https://vk.com/sberbank"} target="_blank">
              <HeaderIcon
                src={publicImage("/icon_vkontakte.svg")}
                alt="Вконтакте"
              />
            </Link>
            <Link to={"https://ok.ru/sber/"} target="_blank">
              <HeaderIcon
                src={publicImage("/icon_odnoklassniki.svg")}
                alt="Одноклассники"
              />
            </Link>
            <Link to={"https://t.me/sberbank"} target="_blank">
              <HeaderIcon
                src={publicImage("/icon_telegram.svg")}
                alt="Телеграм"
              />
            </Link>
          </Flex>
        </HeaderLargeContainer>
        <HeaderSmallContainer>
          <Link to={"http://www.sberbank.ru/ru/person"} target="_blank">
            <StyledLogo src={publicImage("/sber_logo.svg")} alt="Сбербанк" />
          </Link>
          <HeaderFlex>
            <Link to={"/create"}>
              <SmallButton>Создать трек</SmallButton>
            </Link>
            <BurgerMenu toggleOpen={toggleOpen} />
          </HeaderFlex>
        </HeaderSmallContainer>
      </HeaderWrapper>
    </>
  );
};
export default Header;

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 5;
  padding: 20px 20px 0;
  @media (min-width: 375px) {
    padding: 20px 30px 0;
  }
  @media (min-width: 768px) {
    padding: 30px 40px;
  }
  @media (min-width: 1400px) {
    padding: 45px 100px;
  }
`;

const HeaderLargeContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1440px) {
    display: flex;
  }
`;

const HeaderSmallContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  overflow: hidden;
  @media (min-width: 1440px) {
    display: none;
  }
`;

const StyledLogo = styled.img`
  display: block;
  width: 77px;

  @media (min-width: 768px) {
    width: 111px;
  }
  @media (min-width: 1440px) {
    margin-right: 120px;
  }
`;

const HeaderFlex = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  //margin-right: 20px;/
  @media (min-width: 375px) {
    gap: 39px;
    //margin-right: 30px;
  }
  @media (min-width: 768px) {
    gap: 39px;
    //margin-right: 40px;
  }
`;

const NavbarLink = styled(Link)`
  color: white;
  font-family: "SB Sans Display";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
`;

const HeaderIcon = styled.img`
  cursor: pointer;
`;
const HeaderVideoButton = styled.button`
  cursor: pointer;
  height: 40px;
  padding: 0 15px;
  border-radius: 3.4px;
  border: 1px solid #fff;
  background-color: transparent;
  color: white;
  text-align: center;
  font-family: SB Sans Display;
  font-size: 12px;
  font-weight: 300;
  box-sizing: border-box;
`;
const HeaderHistoryButton = styled.button`
  cursor: pointer;
  height: 40px;
  padding: 0 15px;
  border-radius: 3.4px;
  border: 1px solid #fff;
  background-color: transparent;
  color: white;
  text-align: center;
  font-family: SB Sans Display;
  font-size: 12px;
  font-weight: 300;
`;
