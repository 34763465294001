export const songs = [
  {
    id: "st",
    decodedName: "s",
    url: "https://sber.cdnvideo.ru/common/video/mnogogolosravnina.mp3",
    name: "Многоголосие равнины",
    image: ""
  },
  {
    id: "volga",
    decodedName: "v",
    url: "https://sber.cdnvideo.ru/common/video/nadvolgoy.mp3",
    name: <>Песня над&nbsp;Волгой</>
  },
  {
    id: "gorniy",
    decodedName: "g",
    url: "https://sber.cdnvideo.ru/common/video/gorniynapev.mp3",
    name: "Горный напев"
  },
  {
    id: "motiv",
    decodedName: "m",
    url: "https://sber.cdnvideo.ru/common/video/motivnochi.mp3",
    name: "Мотив полярной ночи"
  }
]
