export const instrumentsMap = {
  talyan: {
    encoded: 't',
    name: 'Тальян-гармун',
  },
  jaleyka: {
    encoded: 'j',
    name: 'Жалейка',
  },
  gusli: {
    encoded: 'g',
    name: 'Гусли',
  },
  dungur: {
    encoded: 'd',
    name: 'Дунгур',
  },
  vargan: {
    encoded: 'v',
    name: 'Варган',
  },
  pangur: {
    encoded: 'p',
    name: 'Пандур',
  }
}

export const instruments = [
  instrumentsMap.talyan,
  instrumentsMap.jaleyka,
  instrumentsMap.gusli,
  instrumentsMap.vargan,
  instrumentsMap.dungur,
  instrumentsMap.pangur,
]

export const instrumentsMapByEncoded = Object.values(instrumentsMap).reduce((result, it) => {
  result[it.encoded] = it
  return result
}, {})