import React from "react";
import styled from "styled-components";
import {publicImage} from '../../../shared/util'

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSberLogo src={publicImage('/sber_logo.svg')} alt='' />
        <FooterContactSection>
          <div>
            <FooterContactNumber href='tel:+74955005550'>+7 (495) 500-55-50</FooterContactNumber>
            <FooterContactDescription>
              Телефон для звонков из любой точки мира
            </FooterContactDescription>
          </div>
          <div>
            <FooterContactNumber href='tel:900'>900</FooterContactNumber>
            <FooterContactDescription>
              Бесплатно с мобильного на территории РФ
            </FooterContactDescription>
          </div>
        </FooterContactSection>
        <FooterLegalSection>
          <FooterLegalText href='https://sberbank.ru/ru/person' target='_blank' rel="noreferrer">
            ПАО Сбербанк. Генеральная лицензия Банка России на осуществление банковских операций
            №1481 от 11.08.2015
          </FooterLegalText>
          <FooterLegalText href='https://sberbank.ru/ru/person' target='_blank' rel="noreferrer">
            © 1997—2023 ПАО Сбербанк. Россия, Москва, 117997, ул. Вавилова, д. 19, тел. +7 (495)
            500 5550, 8 800 555 5550.
          </FooterLegalText>
        </FooterLegalSection>
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.footer`
  box-sizing: border-box;
  padding: 40px 16px;
  width: 100%;
  background-color: var(--footer-background);
  
  font-family: "SB Sans Display";
  
  @media (min-width: 768px) {
    padding: 40px 44px;
  }
  
  @media (min-width: 1024px) {
    padding: 40px 28px;
  }
  
  @media (min-width: 1400px) {
    padding: 48px 108px;
  }
`

const FooterContent = styled.div`
  max-width: 680px;
  margin: auto;

  @media (min-width: 1024px) {
    max-width: 968px;
  }
  @media (min-width: 1400px) {
    max-width: 100%;
  }
`

const FooterSberLogo = styled.img`
  display: block;
  width: 84px;
  height: 24px;
`

const FooterContactSection = styled.section`
  display: flex;
  flex-direction: column;

  @media (min-width: 1400px) {
    flex-direction: row;
    justify-content: space-between;
  
    & > div {
      width: 100%;
      &:not(:last-child) { margin-right: 24px; }
    }
  }
`

const FooterContactNumber = styled.a`
  display: block;
  color: var(--footer-contact-info-color);
  font-size: 14px;
  line-height: 144%;
  margin: 24px 0 0 0;
  text-decoration: none;
`

const FooterContactDescription = styled.p`
  color: var(--footer-contact-info-description-color);
  font-size: 14px;
  line-height: 144%;
  font-weight: 400;
  margin: 8px 0 0 0;
`

const FooterLegalSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

const FooterLegalText = styled.a`
  display: block;
  text-decoration: none;
  margin: 12px 0 0 0;
  color: var(--footer-legal-info-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 136%;
`
