import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Flex from "../../../shared/Flex";
import LargeButton from "../../../shared/LargeButton";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Tracks } from "./Tracks/Tracks";
import ProgressBar from "./ProgressBar";
import { publicImage } from "../../../shared/util";

const WelcomePageSection2 = () => {
  const [play, setPlay] = useState(false);
  const [position, setPosition] = useState(0);
  const [currentSongId, setCurrentSongId] = useState("st");
  const songs = useMemo(
    () => [
      {
        id: "st",
        url: "https://sber.cdnvideo.ru/common/video/kalinka.mp3",
        name: "ST - Калинка",
        image: ""
      },
      {
        id: "volga",
        url: "https://sber.cdnvideo.ru/common/video/nadvolgoy.mp3",
        name: "Песня над Волгой"
      },
      {
        id: "gorniy",
        url: "https://sber.cdnvideo.ru/common/video/gorniynapev.mp3",
        name: "Горный напев"
      },
      {
        id: "motiv",
        url: "https://sber.cdnvideo.ru/common/video/motivnochi.mp3",
        name: "Мотив полярной ночи"
      }
    ],
    []
  );



  return (
    <PlayerBackground>
      <PlayerWrapper id="section2">
        <Flex direction="column">
          <Title>ST и нейросеть Сбера</Title>
          <PlayerArticle>
            <PlayerArticleText>сохранили звуки России</PlayerArticleText>
            <PlayerArticleText> и создали новые треки</PlayerArticleText>
          </PlayerArticle>
        </Flex>
        <Flex direction="column" gap="10px">
          <MainPlayer>
            <ProgressBarContainer>
              <ProgressBar
                play={play}
                setPlay={setPlay}
                position={position}
                currentSongId={currentSongId}
              />
              <InSoundLink to={"https://zvuk.com/release/31764797"} target="_blank">
                <SoundOn>Слушайте в Звуке</SoundOn>
              </InSoundLink>
            </ProgressBarContainer>
            <Tracks
              play={play}
              setPlay={setPlay}
              position={position}
              setPosition={setPosition}
              currentSongId={currentSongId}
              setCurrentSongId={setCurrentSongId}
              songs={songs}
            />
          </MainPlayer>
          <Link to={"/create"}>
            <LargeButton>Создать свой трек</LargeButton>
          </Link>
          <PlayerArticle>
            <PlayerArticleTex2t>Слушайте треки и создавайте</PlayerArticleTex2t>
            <PlayerArticleTex2t>на их основе свои</PlayerArticleTex2t>
          </PlayerArticle>
        </Flex>
        <BgImg src={publicImage("/st-bg.svg")} />
        <BgImgTab src={publicImage("/st-bg-tab.svg")} />
        <BgImgMob src={publicImage("/st-bg-mob.svg")} />
      </PlayerWrapper>
      <Footer />
    </PlayerBackground>
  );
};

export default WelcomePageSection2;

const PlayerBackground = styled.div`
  position: relative;
  margin-top: -400px;
  padding-top: 400px;
  overflow-y: hidden;
`;
const InSoundLink = styled(Link)`
  position: absolute;
  top: 16px;
  right: 0;
  transform: translateX(60%);
  
  @media (min-width: 375px) {
    top: 50px;
    
    transform: translateX(75%);
  }
  @media (min-width: 768px) {
    top: 95px;
    transform: translateX(80%);
  }
  @media (min-width: 1024px) {
    top: 130px;
    transform: translateX(75%);
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding: 20px 20px;
  padding-bottom: 40px;
  @media (min-width: 375px) {
    padding: 54px 30px;
  }
  @media (min-width: 768px) {
    padding: 50px 40px;
  }
  @media (min-width: 1024px) {
    padding: 50px 200px 40px;
  }
  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

const SoundOn = styled.button`
  cursor: pointer;
  padding: 8px 16px;
  width: fit-content;
  white-space: nowrap;
  border-radius: 65px;
  border: solid 1.2px white;
  //position: relative;
  //left: 45vw;
  //bottom: 24vh;
  text-align: center;
  color: white;
  font-size: 10px;
  font-weight: 300;
  background-color: transparent;
  @media (min-width: 375px) {
    left: 37vw;
    bottom: 16vh;
  }
  @media (min-width: 768px) {
    //width: 145px;
    border-radius: 198px;
    border: 2px solid white;
    font-size: 24px;
    //left: 32vw;
    //bottom: 22vh;
  }
  @media (min-width: 1400px) {
    //left: 22vw;
    //bottom: 32vh;
  }
`;
const Title = styled.div`
  font-size: 24px;
  color: white;
  font-family: "SB Sans Display";
  font-weight: 300;
  letter-spacing: 0.48px;
  @media (min-width: 768px) {
    font-size: 56px;
    letter-spacing: 1.12px;
  }
`;

const PlayerArticle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  @media (min-width: 768px) {
    flex-direction: row;
    font-size: 26px;
    gap: 10px;
  }
`;

const MainPlayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 375px) {
    margin-top: 57px;
  }
  @media (min-width: 1400px) {
    flex-direction: row;
    gap: 100px;
  }
`;

const PlayerArticleText = styled.span`
  color: #fff;
  text-align: center;
  font-family: "SB Sans Display";
  font-size: 16px;
  font-weight: 300;
  opacity: 0.7;
  @media (min-width: 768px) {
    font-size: 26px;
  }
`;

const PlayerArticleTex2t = styled.span`
  color: #fff;
  text-align: center;
  font-family: "SB Sans Display";
  font-size: 16px;
  font-weight: 300;
  opacity: 0.7;
  line-height: 150%;
  @media (min-width: 768px) {
    font-size: 26px;
  }
`;

const ProgressBarContainer = styled.div`
  position: relative;
  width: 174px;
  height: 174px;
  @media (min-width: 768px) {
    width: 303px;
    height: 303px;
  }
  @media (min-width: 1024px) {
    width: 400px;
    height: 400px;
  }
`;

const stBg = `
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transform: translateY(-30%);
  opacity: 80%;
  pointer-events: none;
  user-select: none;
  z-index: 4;
  filter: blur(20px);
`;

const BgImg = styled.img`
  ${stBg};
  @media (max-width: 1023px) {
    display: none;
  }
`;
const BgImgTab = styled.img`
  ${stBg};
  display: none;
  @media (min-width: 768px) and (max-width: 1023px) {
    display: block;
  }
`;
const BgImgMob = styled.img`
  ${stBg};
  display: none;
  transform: translateY(-10%);
  @media (max-width: 767px) {
    display: block;
  }
`;
