import React from "react";
import styled from "styled-components";
import { bool } from "prop-types";
import { StyledMenu } from "./Styled.Menu";
import { Link } from "react-router-dom";
import { publicImage } from "../../../../shared/util";

const Menu = ({ open, toggleOpen }) => {
  return (
    <StyledMenu open={open}>
      <MenuBackground>
        <MenuWrapper>
          <MenuHeader>
            <StyledLogo
              src={publicImage("/sber_logo.svg")}
              alt="Сбербанк"
              target="_blank"
            />
          </MenuHeader>
          <MenuList>
            <NavbarLink to="/create">
              <MenuButton>Создать трек</MenuButton>
            </NavbarLink>
            <NavbarLink onClick={toggleOpen}>
              <MenuButton>Видео</MenuButton>
            </NavbarLink>
            <NavbarLink to="/history">
              <MenuButton>Как создавался проект</MenuButton>
            </NavbarLink>
          </MenuList>
          <Container>
            <MenuIcons>
              <Link to={"https://vk.com/sberbank"} target="_blank">
                <MenuIcon
                  src={publicImage("/icon_vkontakte.svg")}
                  alt="Вконтакте"
                />
              </Link>
              <Link to={"https://ok.ru/sber/"} target="_blank">
                <MenuIcon
                  src={publicImage("/icon_odnoklassniki.svg")}
                  alt="Одноклассники"
                />
              </Link>
              <Link to={"https://t.me/sberbank"} target="_blank">
                <MenuIcon
                  src={publicImage("/icon_telegram.svg")}
                  alt="Телеграм"
                />
              </Link>
            </MenuIcons>
          </Container>
        </MenuWrapper>
      </MenuBackground>
    </StyledMenu>
  );
};
Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;

const MenuWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 20px 40px;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 375px) {
    padding: 20px 30px 40px;
  }
  @media (min-width: 768px) {
    padding: 35px 40px 80px;
  }
  @media (min-width: 1400px) {
    display: none;
  }
`;

const MenuHeader = styled.div`
  //margin: 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 375px) {
    //margin: 20px 29px 0px;
  }
  @media (min-width: 768px) {
    //margin: 31px 40px 0;
  }
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  @media (min-width: 375px) {
    gap: 13px;
  }
  @media (min-width: 768px) {
    gap: 25px;
    font-size: 56px;
    letter-spacing: -1.12px;
  }
  @media (min-width: 1024px) {
    gap: 25px;
    font-size: 56px;
    letter-spacing: -1.12px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 43px;
`;

const MenuIcons = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 42px;
`;

const MenuBackground = styled.div`
  background: url(${publicImage("/menubg.svg")}) no-repeat;
  background-color: black;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  overflow: hidden;
`;

const StyledLogo = styled.img`
  width: 77px;
  height: 20px;
  display: block;
  @media (min-width: 768px) {
    width: 111px;
    height: 30px;
  }
`;

const NavbarLink = styled(Link)`
  text-decoration: none;
`;

const MenuIcon = styled.img`
  cursor: pointer;
`;

const MenuButton = styled.button`
  width: 280px;
  height: 46px;
  color: white;
  text-align: center;
  font-family: SB Sans Display;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  background: url(${publicImage("/menuellipse.svg")}) no-repeat center;
  background-size: fill;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  border: 1px solid #fff;
  @media (min-width: 375px) {
    width: 318.025px;
    height: 52.247px;
    border-radius: 13.63px;
    border: 1.136px solid #fff;
  }
  @media (min-width: 768px) {
    width: 400px;
    height: 60px;
    font-size: 24px;
  }
`;
