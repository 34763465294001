import { songs } from '../config/songs'
import { instruments, instrumentsMap, instrumentsMapByEncoded } from '../config/instruments'

export class SongEncoder {
  static encode({
    currentSongId,
    dungurPlay,
    gusliPlay,
    jaleykaPlay,
    pangurPlay,
    talyanPlay,
    varganPlay,
  }) {
    let result = "";
    const songEncode = songs.find(song => song.id === currentSongId)
      .decodedName;
    result += songEncode;


    if (talyanPlay) {
      result += instrumentsMap.talyan.encoded;
    }
    if (jaleykaPlay) {
      result += instrumentsMap.jaleyka.encoded;
    }
    if (gusliPlay) {
      result += instrumentsMap.gusli.encoded;
    }
    if (dungurPlay) {
      result += instrumentsMap.dungur.encoded;
    }
    if (varganPlay) {
      result += instrumentsMap.vargan.encoded;
    }
    if (pangurPlay) {
      result += instrumentsMap.pangur.encoded;
    }
    return result;
  }

  static decode(value) {
    if (typeof value !== 'string' || value.length < 1) {
      console.error('Invalid encoded string')
      return {
        song: null,
        instruments: [],
      }
    }
    const firstSymbol = value.slice(0, 1)
    const song = songs.find(it => it.decodedName === firstSymbol)
    if (!song) {
      console.error(`Song by decodedName of "${firstSymbol}" was not found`)
      return {
        song: null,
        instruments: [],
      }
    }

    const rest = Array.from(value.slice(1))
    const foundInstruments = []
    for (let char of rest) {
      const instrument = instrumentsMapByEncoded[char]
      if (!instrument)
        continue
      foundInstruments.push(instrument)
    }

    return {
      song,
      instruments: foundInstruments,
    }
  }
}