import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const SectionBGVideo = () => {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <Video
      src="https://sber.cdnvideo.ru/common/video/podlojka.mp4"
      ref={videoRef}
      loop
      autoPlay
      muted
      playsInline
    />
  );
};

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  background-color: black;
`;

export default SectionBGVideo;
