import WelcomePage from "./Pages/WelcomePage/WelcomePage";
import "./App.css";
import { createHashRouter, RouterProvider } from "react-router-dom";
import CreatePage from "./Pages/CreatePage/CreatePage";
import ResultPage from "./Pages/ResultPage/ResultPage";
import History from "./Pages/HistoryPage/History";
import Page404 from "./Pages/Page404/Page404";
import { isIE } from "react-device-detect";
import { useLocalStorage } from "usehooks-ts";
import CookiesModal from "./shared/CookiesModal";
import PlugPage from "./Pages/PlugPage/PlugPage";

const router = createHashRouter([
  {
    path: "/",
    element: <WelcomePage />,
  },
  {
    path: "create",
    element: <CreatePage />,
  },
  {
    path: "result",
    element: <ResultPage />,
  },
  {
    path: "history",
    element: <History />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
]);

const App = () => {
  const [isCookieOpen, setIsCookieOpen] = useLocalStorage("cookieOpen", true);
  return isIE ? (
    <PlugPage />
  ) : (
    <>
      <RouterProvider router={router} />
      {isCookieOpen ? (
        <CookiesModal
          closeCookieModal={() => {
            setIsCookieOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

export default App;
