import React, {useRef, useEffect, useState, useMemo} from 'react';
import { bool } from "prop-types";
import styled from "styled-components";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { publicImage } from "./util";
import {useSearchParams} from 'react-router-dom'

let isFirstRender = true
let mountTimeoutId = null

const VideoModal = ({ open, toggleOpen }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchShowed = searchParams.get('video') === 'open'

  const showed = useMemo(() => searchShowed, [searchShowed])

  const videoRef = useRef(undefined);

  const [showedPlayButton, setShowedPlayButton] = useState(false)

  const handleClickButtonPlay = () => {
    videoRef.current.play()
    setShowedPlayButton(false)
  }

  useEffect(() => {
    mountTimeoutId = setTimeout(() => {
      if (isFirstRender)
        isFirstRender = false
    }, 100)
    return () => {
      if (mountTimeoutId)
        clearTimeout(mountTimeoutId)
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      open
        ? searchParams.set('video', 'open')
        : searchParams.delete('video')
      setSearchParams(searchParams)
    }
  }, [open])

  useEffect(() => {
    showed
      ? disableBodyScroll(videoRef.current)
      : enableBodyScroll(videoRef.current);

    if (showed && videoRef.current.paused) {
      videoRef.current.play().then(_ => {
        setShowedPlayButton(false)
      }).catch(_ => {
        setShowedPlayButton(true)
      })
      videoRef.current.currentTime = 0;
    }
    if (!showed && !videoRef.current.paused) {
      videoRef.current.pause()
    }
  }, [showed]);

  return (
    <>
      <VideoWrapper open={showed}>
        <Video
          src="https://sber.cdnvideo.ru/common/video/main.mp4"
          ref={videoRef}
          loop
          playsInline
        />
        {showedPlayButton && <PlayButton onClick={handleClickButtonPlay} />}
      </VideoWrapper>
      <Button
        onClick={toggleOpen}
        open={showed}
        src={publicImage("/closeicon.svg")}
        alt="close"
      />
    </>
  );
};

VideoModal.propTypes = {
  open: bool.isRequired,
};

export default VideoModal;

const VideoWrapper = styled.div`
  position: fixed;
  right: 100%;
  top: 50%;
  z-index: 7;
  width: 100%;
  height: 100vh;
  transition: transform;
  background-color: black;
  transform: ${({ open }) => (open ? "translateX(100%) translateY(-50%)" : "translateX(-100%) translateY(-50%)")};
`

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-image: url(${publicImage('/play-button.png')});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const Video = styled.video`
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 20px;
  object-fit: contain;
  object-position: center;
  
  background: rgba(0,0,0);
`;

const Button = styled.img`
  color: red;
  z-index: 7;
  position: fixed;
  top: 0;
  right: 0;
  transform: ${({ open }) => (!open ? "translateX(100vw)" : "translateX(0)")};
  display: block;
  width: 35px;
  cursor: pointer;
`;
