import styled from "styled-components";

export const StyledMenu = styled.nav`
  transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
  height: 100vh;
  width: 100%;
  position: absolute;
  right: 100%;
  top: 0;
  z-index: 3;
  transition: opacity 0.2s;
  opacity: ${({ open }) => open ? 1 : 0 };
`;
