/**
 *
 * @param {string} pathToImage
 * @returns path to image with public url
 */
export const publicImage = (pathToImage) => {
  return process.env.PUBLIC_URL + pathToImage;
};

export const copyByExecCommand = (el) => {
  el.focus()
  const oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange()

  el.contentEditable = String(true)
  el.readOnly = false
  range.selectNodeContents(el)

  const s = window.getSelection()
  s.removeAllRanges()
  s.addRange(range)

  el.setSelectionRange(0, 999999) // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable
  el.readOnly = oldReadOnly

  document.execCommand('copy')

  el.blur()
}

export const copyText = (text = '', el = null, {
  onSuccess = () => {},
  onError = () => {},
} = {}) => {
  navigator.clipboard.writeText(text)
    .then(c => c, () => el && copyByExecCommand(el))
    .then(onSuccess)
    .catch(onError)
}
