import React from "react";
import styled from "styled-components";
import Footer from "../../WelcomePage/components/Footer";

const ResultPageSection3 = () => {
  return (
    <ResultpageSection3Wrapper>
      <video src="https://sber.cdnvideo.ru/common/video/volna.mp4" muted autoPlay playsInline loop style={{
        width: '100%',
      }} />
      <Footer />
    </ResultpageSection3Wrapper>
  );
};

export default ResultPageSection3;

const ResultpageSection3Wrapper = styled.div`
  background-color: black;
  z-index: 1;
`;
