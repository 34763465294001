import styled from "styled-components";
import {useEffect, useRef} from "react";

const Video = styled.video`
  width: 95%;
  border-radius: 50%;
  position: relative;
  z-index: 2;

  
  &._hide {
  display: none;
  }
  
  &._visible {
  display: block;
  }
`;

export default function InstrumentVideo({ src, hide }) {

  useEffect(() => {
    // костыль сафари
    videoRef.current.setAttribute('muted', '');
    videoRef.current.play();
  }, []);

  const videoRef = useRef(null);
  return (
    <Video
      ref={videoRef}
      src={src}
      preload="false"
      autoPlay
      controls={false}
      playsInline={true}
      loop
      muted={true}
      className={hide ? '_hide' : '_visible'}
    />
  );
}
