import { useMemo } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";
import { publicImage } from "../../../shared/util";

const ProgressBar = ({ play, setPlay, position, currentSongId }) => {
  const percentage = position * 100 || 0;

  const backgroundImagesUrl = useMemo(() => {
    const backgroundImages = {
      st: publicImage("/index-player/st.svg"),
      volga: "https://sber.cdnvideo.ru/common/files/zvuki/volga.png",
      gorniy: publicImage("/gorniy.png"),
      motiv: publicImage("/motiv.png"),
    };
    return backgroundImages[currentSongId] || publicImage("/st.svg");
  }, [currentSongId]);

  return (
    <Container>
      <CircularProgressbarWithChildren
        value={percentage}
        circleRatio={0.5}
        counterClockwise
        strokeWidth={2}
        styles={{
          root: {
            transform: "rotate(0.75turn)",
          },
          path: {
            stroke: `#91D356`,
            strokeLinecap: "round",
          },
          trail: {
            stroke: "#103319",
          },
        }}>
        <PlayerBG src={backgroundImagesUrl} />
      </CircularProgressbarWithChildren>
      <PlayPauseButton onClick={() => setPlay(!play)}>
        {play ? (
          <PlayerIcons src={publicImage("/pause_icon.svg")} alt="pause" />
        ) : (
          <PlayerIcons src={publicImage("/play_icon_green.svg")} alt="play" />
        )}
      </PlayPauseButton>
    </Container>
  );
};

export default ProgressBar;

const PlayerBG = styled.img`
  width: 80%;
  border-radius: 50%;
`;
const Container = styled.div`
  position: relative;
`;

const PlayPauseButton = styled.button`
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  background-color: black;
  position: absolute;
  border: none;
  left: 50%;
  margin-left: -17.5px;
  top: 50%;
  margin-top: -17.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    margin-left: -40px;
    margin-top: -40px;
  }
`;

const PlayerIcons = styled.img`
  display: block;
  width: 6px;
  @media (min-width: 768px) {
    width: 15px;
    gap: 25px;
  }
`;
