import styled from "styled-components";
import { publicImage } from "./util";

const SmallButton = (props) => {
  return <ButtonSmall {...props} />;
};

export default SmallButton;

export const ButtonSmall = styled.button`
  text-align: center;
  color: white;
  width: 80px;
  height: 20px;
  font-size: 10px;
  font-family: "SB Sans Display";
  font-weight: 300;
  cursor: pointer;
  border-radius: 3.4px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: url(${publicImage("/btnellipse.svg")}) center no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.3);

  @media (min-width: 768px) {
    text-align: center;
    width: 108px;
    height: 40px;
    font-size: 12px;
  }
`;
