import React from "react";
import { useEffect } from "react";
import { StyledBurger } from "./Styled.Burger";

const BurgerButton = ({ open, setOpen }) => {
  useEffect(() => {
    document.body.style.position = open ? "fixed" : "";
    return () => {
      document.body.style.position = "";
    };
  }, [open]);

  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
    </StyledBurger>
  );
};

export default BurgerButton;
