import React from "react";
import styled from "styled-components";

const Flex = (props) => {
  return <StyledFlex {...props} />;
};

export default Flex;

const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "space-between"};
  margin: ${({ margin }) => margin || "0"};
  gap: ${({ gap }) => gap || "0"};
  padding: ${({ padding }) => padding || "0"};
  width: ${({ width }) => width};
`;
