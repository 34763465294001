import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactHowler from "react-howler";
import styled from "styled-components";
import { publicImage } from "../../../../shared/util";

export const Tracks = ({
  play,
  setPlay,
  setPosition,
  songs,
  currentSongId,
  setCurrentSongId,
}) => {
  const songRef = useRef(null);
  const currentSongObject = useMemo(() => {
    return songs.find((song) => song.id === currentSongId);
  }, [currentSongId, songs]);

  const restSongsArray = useMemo(() => {
    return songs.filter((song) => song.id !== currentSongId);
  }, [currentSongId, songs]);

  const handleSongClick = (id) => {
    setCurrentSongId(id);
    if (id === currentSongId) {
      setPlay(!play);
    } else {
      setPlay(true);
    }
  };

  const updatePosition = useCallback(() => {
    if (play) {
      setPosition(songRef.current.seek() / songRef.current.duration());
    }
  }, [play, setPosition, songRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      updatePosition();
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [updatePosition]);

  return (
    <TracksWrapper>
      <PlayingTrack onClick={() => handleSongClick(currentSongId)}>
        {play ? (
          <PlayerIcons src={publicImage("/pause_icon.svg")} alt="pause" />
        ) : (
          <PlayerIcons src={publicImage("/play_icon_green.svg")} alt="play" />
        )}
        {currentSongObject.name}
      </PlayingTrack>
      <FlexTracks>
        {restSongsArray.map((song) => (
          <PendingTrack key={song.id} onClick={() => handleSongClick(song.id)}>
            <PlayerIcons src={publicImage("/play_icon.svg")} />
            {song.name}
          </PendingTrack>
        ))}
      </FlexTracks>
      <ReactHowler src={currentSongObject.url} playing={play} ref={songRef} />
    </TracksWrapper>
  );
};

const PlayingTrack = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-size: 12px;
  color: #8dff57;
  font-family: "SB Sans Display";
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 26px;
  }
  @media (min-width: 1400px) {
    gap: 25px;
    margin-left: -3.5vw;
  }
`;

const PlayerIcons = styled.img`
  display: block;
  position: relative;
  width: 6px;
  @media (min-width: 768px) {
    width: 15px;
    gap: 25px;
  }
`;

const PendingTrack = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-size: 10px;
  color: white;
  font-family: "SB Sans Display";
  font-weight: 300;
  opacity: 0.7;
  white-space: nowrap;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const FlexTracks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (min-width: 768px) {
    gap: 50px;
  }
  @media (min-width: 1400px) {
    flex-direction: column;
  }
  @media (max-width: 1023px) {
    justify-content: center;
    row-gap: 20px;
  }
  @media (max-width: 767px) {
    justify-content: center;
    row-gap: 8px;
  }
`;

const TracksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  @media (min-width: 768px) {
    gap: 20px;
    margin-top: 26px;
  }
  @media (min-width: 1400px) {
    gap: 50px;
    align-items: start;
    margin-left: 213px;
    width: 280px;
  }
`;
