import React, { useState } from "react";
import styled from "styled-components";
import SectionBGVideo from "./HistoryBGVideo";
import Header from "../WelcomePage/components/Header";
import VideoModal from "../../shared/VideoModal";
import {publicImage} from "../../shared/util";

const HistoryPageSection1 = () => {
  const [open, setOpen] = useState(false);
  const handleToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <SectionBGVideo />
      <HistoryPageSection1Wrapper>
        <Header toggleOpen={setOpen} />
        <FlexContainer>
          <HistoryPageSection1Title>
            Как создавались Звуки России
          </HistoryPageSection1Title>
          <Article1Container>
            <HistoryPageArticle1>
              Мы&nbsp;записали аутентичное звучание 6&nbsp;народных инструментов из&nbsp;разных регионов
            </HistoryPageArticle1>
            <HistoryPagePlayVideoButton onClick={() => setOpen(true)}>
              <PlayIcon src={publicImage("/playicon.svg")} alt="Видео" />
              <VideoButtonText>Видео</VideoButtonText>
            </HistoryPagePlayVideoButton>
          </Article1Container>
          <HistoryPageArticle2>
            С&nbsp;помощью&nbsp;ИИ добавили современное звучание народным мелодиям, чтобы объединить жителей нашей многомузыкальной страны
          </HistoryPageArticle2>
        </FlexContainer>
      </HistoryPageSection1Wrapper>
      <VideoModal open={open} toggleOpen={handleToggleOpen} />
    </Container>
  );
};

export default HistoryPageSection1;

const HistoryPageSection1Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 4;
`;
const HistoryPageSection1Title = styled.h2`
  position: relative;
  color: white;
  font-family: "SB Sans Display";
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-bottom: 8.8vh;
  z-index: 1;
  @media (min-width: 768px) {
    font-size: 56px;
    width: 567px;
    margin-top: 4.8vh;
  }
  @media (min-width: 1400px) {
    margin-bottom: 9vh;
  }
`;
const Article1Container = styled.div`
  diplay: flex;
  flex-direction: column;
  margin-left: 23.5vw;
  margin-bottom: 409px;
  @media (min-width: 768px) {
    margin-left: 23.4vw;
  }
  @media (min-width: 1400px) {
    margin-left: 21vw;
  }
  @media (max-width: 1023px) {
    margin-bottom: 191px;
  }
  @media (max-width: 767px) {
    margin-bottom: 148px; 
  }
`;
const HistoryPageArticle1 = styled.div`
  position: relative;
  z-index: 1;
  diplay: block;
  width: 159px;
  color: white;
  font-family: "SB Sans Display";
  font-size: 14px;
  font-weight: 300;
  line-height: 130%;
  margin-bottom: 3.5vh;
  @media (min-width: 768px) {
    margin-bottom: 2.7vh;
    font-size: 24px;
    width: 350px;
  }
`;
const HistoryPagePlayVideoButton = styled.button`
  cursor: pointer;
  position: relative;
  z-index: 1;
  border-radius: 86.786px;
  border: 1px solid #fff;
  width: 73px;
  height: 30px;
  background-color: white;
  display: flex;
  gap: 5px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    width: 160px;
    height: 66px;
  }
`;
const VideoButtonText = styled.span`
  color: white;
  text-align: center;
  font-family: SB Sans Display;
  font-size: 10px;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;
const PlayIcon = styled.img`
  display: block;
  width: 10%;
`;
const HistoryPageArticle2 = styled.div`
  display: block;
  width: 183px;
  height: 72px;
  color: white;
  font-family: SB Sans Display;
  font-size: 14px;
  font-weight: 300;
  line-height: 130%;
  z-index: 1;
  position: relative;
  @media (min-width: 768px) {
    font-size: 24px;
    width: 341px;
  }
`;
const Container = styled.div`
  background-color: black;
`;
const FlexContainer = styled.div`
  max-width: 1400px;
  position: relative;
  margin: 8vh auto 0;
  padding: 0 330px;
  @media (max-width: 1023px) {
    max-width: 688px;
    padding: 0;
    padding-left: 120px;
  }
  @media (max-width: 767px) {
    padding: 0 40px;
    margin-bottom: 0;  
  }
`;
