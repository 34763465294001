import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {useLocalStorage} from "usehooks-ts";
import styled from "styled-components";
import Lottie from "lottie-react";
import ReactHowler from "react-howler";
import animationTalyan from "../../animations/TOLYAN.json";
import animationDungur from "../../animations/DUNGUR.json";
import animationGusli from "../../animations/GUSLI.json";
import animationJaleyka from "../../animations/JALEIKA.json";
import animationPandur from "../../animations/PANDUR.json";
import animationVargan from "../../animations/VARGAN.json";
import OnBoarding from "./OnBoarding";
import InstrumentVideo from "./InstrumentVideo";
import {publicImage} from "../../shared/util";
import popupData from "./popups";
import Popup from "./Popup";
import {songs} from '../../config/songs'
import {SongEncoder} from '../../utils/songEncoder'
import {urlSearchParam} from '../../config/variables'

const CreatePage = () => {
  const [currentSongId, setCurrentSongId] = useState("st");
  const [play, setPlay] = useState(false);
  const currentSongRef = useRef(null);
  const talyanSongRef = useRef(null);
  const jaleykaSongRef = useRef(null);
  const gusliSongRef = useRef(null);
  const dungurSongRef = useRef(null);
  const varganSongRef = useRef(null);
  const pangurSongRef = useRef(null);

  let initialState = 0;
  const [onBoardingLocalStorage, setOnBoardingLocalStorage] = useLocalStorage("onBoarding", false);
  if (!onBoardingLocalStorage) {
    initialState = 1;
  } else {
    initialState = 0;
  }
  const [onboardingModalState, setOnboardingModalState] = useState(initialState);

  const openOnboarding = () => {
    setOnboardingModalState(1);
  };

  const [talyanPlay, setTalyanPlay] = useState(false);
  const [jaleykaPlay, setJaleykaPlay] = useState(false);
  const [gusliPlay, setGusliPlay] = useState(false);
  const [dungurPlay, setDungurPlay] = useState(false);
  const [varganPlay, setVarganPlay] = useState(false);
  const [pangurPlay, setPandurPlay] = useState(false);

  function disableAllInstruments() {
    setTalyanPlay(false);
    setJaleykaPlay(false);
    setGusliPlay(false);
    setDungurPlay(false);
    setVarganPlay(false);
    setPandurPlay(false);
  }

  const [popupType, setPopupType] = useState(null);

  const currentSongObject = useMemo(() => {
    return songs.find(song => song.id === currentSongId);
  }, [currentSongId, songs]);

  const getInstrumentAudio = instrument => {
    const {id} = currentSongObject;
    if (!["volga", "gorniy", "motiv"].includes(id)) {
      return `https://sber.cdnvideo.ru/common/video/ravnina${instrument}.mp3`;
    }
    return `https://sber.cdnvideo.ru/common/video/${id}${instrument}.mp3`;
  };

  const handleSongClick = id => {
    setCurrentSongId(id);
    if (id === currentSongId) {
      if (play) {
        disableAllInstruments();
      }
      setPlay(!play);
    } else {
      setPlay(true);
    }
  };

  const handleInstrumentClick = name => {
    if (!play) return;
    switch (name) {
      case "talyan": {
        setTalyanPlay(!talyanPlay);
        talyanSongRef.current.seek(currentSongRef.current.seek());
        break;
      }
      case "jaleyka": {
        if (!jaleykaPlay) {
          jaleykaSongRef.current.seek(currentSongRef.current.seek());
        }
        setJaleykaPlay(!jaleykaPlay);
        break;
      }
      case "gusli": {
        if (!gusliPlay) {
          gusliSongRef.current.seek(currentSongRef.current.seek());
        }
        setGusliPlay(!gusliPlay);
        break;
      }
      case "dungur": {
        if (!dungurPlay) {
          dungurSongRef.current.seek(currentSongRef.current.seek());
        }
        setDungurPlay(!dungurPlay);
        break;
      }
      case "vargan": {
        if (!varganPlay) {
          varganSongRef.current.seek(currentSongRef.current.seek());
        }
        setVarganPlay(!varganPlay);
        break;
      }
      case "pandur": {
        if (!pangurPlay) {
          pangurSongRef.current.seek(currentSongRef.current.seek());
        }
        setPandurPlay(!pangurPlay);
        break;
      }
      default:
        return;
    }
  };


  const decodedSong = useMemo(() => SongEncoder.encode({
    currentSongId,
    dungurPlay,
    gusliPlay,
    jaleykaPlay,
    pangurPlay,
    talyanPlay,
    varganPlay
  }), [
    currentSongId,
    dungurPlay,
    gusliPlay,
    jaleykaPlay,
    pangurPlay,
    talyanPlay,
    varganPlay,
  ])

  useEffect(() => {
    if (popupType !== null && play) {
      handleSongClick(currentSongId)
    }
  }, [popupType])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
      <CreatepageWrapper>
        <Container>
          <OnBoarding
            onboardingModalState={onboardingModalState}
            setOnboardingModalState={setOnboardingModalState}
            setOnBoardingLocalStorage={setOnBoardingLocalStorage}
          />
          <CreatePageHeader>
            <StyledLink to={"/"}>
              <BackArrow src={publicImage("/backarrow.svg")} alt="Вернуться"/>
              <ReturnBackText>Вернуться</ReturnBackText>
            </StyledLink>
            <HelpText onClick={openOnboarding}>Подсказки</HelpText>
          </CreatePageHeader>
          <SongsContainer>
            <>
              {songs.map(({id, name}) => (
                  <SongWrapper onClick={() => handleSongClick(id)} isCurrent={id === currentSongId}>
                    <PlayButton>
                      {id === currentSongId && play ? (
                          <PlayPauseButtonIcons
                              src={publicImage("/createpauseicon.svg")}
                              alt="pause"
                          />
                      ) : (
                          <PlayPauseButtonIcons
                              src={publicImage("/createplayicon.svg")}
                              alt="play"
                          />
                      )}
                    </PlayButton>
                    <SongText>{name}</SongText>
                  </SongWrapper>
              ))}
            </>
          </SongsContainer>
          <InstrumentsContainer>
            <TalyanContainer onClick={() => handleInstrumentClick("talyan")}>
              <Inform
                  src={publicImage("/inform.svg")}
                  alt="inform"
                  onClick={() => setPopupType("talyan")}
              />
              <TalyanSign src={publicImage("/talyansign.svg")} alt="talyan"/>
              <Talyan>
                {talyanPlay ? null : (
                    <TalyanImage
                        src={publicImage("/instruments/talyan.png")}
                        alt="тальян"
                    />
                )}
                <InstrumentVideo
                    src="https://sber.cdnvideo.ru/common/video/garmonloop.mp4"
                    hide={!talyanPlay}
                />
                {talyanPlay ? (
                    <Animation animationData={animationTalyan}/>
                ) : null}
              </Talyan>
            </TalyanContainer>
            <JaleykaContainer onClick={() => handleInstrumentClick("jaleyka")}>
              <Inform
                  src={publicImage("/inform.svg")}
                  alt="inform"
                  onClick={() => setPopupType("jaleika")}
              />
              <JaleykaSign src={publicImage("/jaleykasign.svg")} alt="jaleyka"/>
              <Jaleyka>
                {jaleykaPlay ? null : (
                    <JaleykaImage
                        src={publicImage("/instruments/jaleika.png")}
                        alt="Жалейка"
                    />
                )}
                <InstrumentVideo
                    src="https://sber.cdnvideo.ru/common/video/jaleikaloop.mp4"
                    hide={!jaleykaPlay}
                />
                {jaleykaPlay ? (
                    <Animation animationData={animationJaleyka}/>
                ) : null}
              </Jaleyka>
            </JaleykaContainer>
            <GusliContainer onClick={() => handleInstrumentClick("gusli")}>
              <Inform
                  src={publicImage("/inform.svg")}
                  alt="inform"
                  onClick={() => setPopupType("gusli")}
              />
              <GusliSign src={publicImage("/guslysign.svg")} alt="gusli"/>
              <Gusli>
                {gusliPlay ? null : (
                    <GusliImage
                        src={publicImage("/instruments/gusli.png")}
                        alt="Гусли"
                    />
                )}
                <InstrumentVideo
                    src="https://sber.cdnvideo.ru/common/video/gusliloop.mp4"
                    hide={!gusliPlay}
                />
                {gusliPlay ? <Animation animationData={animationGusli}/> : null}
              </Gusli>
            </GusliContainer>
            <VarganContainer onClick={() => handleInstrumentClick("vargan")}>
              <Inform
                  src={publicImage("/inform.svg")}
                  alt="inform"
                  onClick={() => setPopupType("vargan")}
              />
              <VarganSign src={publicImage("/vargansign.svg")} alt="vargan"/>
              <Vargan>
                {varganPlay ? null : (
                    <VarganImage
                        src={publicImage("/instruments/vargan.png")}
                        alt="Варган"
                    />
                )}
                <InstrumentVideo
                    src="https://sber.cdnvideo.ru/common/video/varganloop.mp4"
                    hide={!varganPlay}
                />
                {varganPlay ? (
                    <Animation animationData={animationVargan}/>
                ) : null}
              </Vargan>
            </VarganContainer>
            <DungurContainer onClick={() => handleInstrumentClick("dungur")}>
              <Inform
                  src={publicImage("/inform.svg")}
                  alt="inform"
                  onClick={() => setPopupType("dungur")}
              />
              <DungurSign src={publicImage("/dungursign.svg")} alt="dungur"/>
              <Dungur>
                {dungurPlay ? null : (
                    <DungurImage
                        src={publicImage("/instruments/dungur.png")}
                        alt="Дунгур"
                    />
                )}
                <InstrumentVideo
                    src="https://sber.cdnvideo.ru/common/video/shamanloop.mp4"
                    hide={!dungurPlay}
                />
                {dungurPlay ? (
                    <Animation animationData={animationDungur}/>
                ) : null}
              </Dungur>
            </DungurContainer>
            <PandurContainer onClick={() => handleInstrumentClick("pandur")}>
              <Inform
                  src={publicImage("/inform.svg")}
                  alt="inform"
                  onClick={() => setPopupType("pandur")}
              />
              <PandurSign src={publicImage("/pandursign.svg")} alt="pandur"/>
              <Pandur>
                {pangurPlay ? null : (
                    <PandurImage
                        src={publicImage("/instruments/pandur.png")}
                        alt="Пандур"
                    />
                )}
                <InstrumentVideo
                    src="https://sber.cdnvideo.ru/common/video/pandurloop.mp4"
                    hide={!pangurPlay}
                />
                {pangurPlay ? (
                    <Animation animationData={animationPandur}/>
                ) : null}
              </Pandur>
            </PandurContainer>
          </InstrumentsContainer>
          <>
            <ReactHowler
                src={currentSongObject.url}
                playing={play}
                ref={currentSongRef}
                preload={false}
            />
            <ReactHowler
                src={getInstrumentAudio("garmun")}
                playing={talyanPlay}
                ref={talyanSongRef}
            />
            <ReactHowler
                src={getInstrumentAudio("jaleika")}
                playing={jaleykaPlay}
                ref={jaleykaSongRef}
            />
            <ReactHowler
                src={getInstrumentAudio("gusli")}
                playing={gusliPlay}
                ref={gusliSongRef}
            />
            <ReactHowler
                src={getInstrumentAudio("dungur")}
                playing={dungurPlay}
                ref={dungurSongRef}
            />
            <ReactHowler
                src={getInstrumentAudio("vargan")}
                playing={varganPlay}
                ref={varganSongRef}
            />
            <ReactHowler
                src={getInstrumentAudio("pandur")}
                playing={pangurPlay}
                ref={pangurSongRef}
            />
          </>
        </Container>
        <Link to={`/result?${urlSearchParam.song}=${decodedSong}`}>
          <FinishButton>Завершить</FinishButton>
        </Link>
        {popupType !== null && (
            <Popup
                onClose={() => setPopupType(null)}
                text={popupData[popupType].text}
                video={popupData[popupType].video}
            />
        )}
      </CreatepageWrapper>
  );
};

export default CreatePage;

const SongsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const SongWrapper = styled.button(({isCurrent}) => `
  cursor: pointer;
  z-index: 2;
  display: flex;
  gap: 6px;
  align-items: center;
  min-width: 136px;
  width: 100%;
  height: 33px;
  justify-content: flex-start;
  opacity: .7;
  
  border-radius: 12px;
  border: 0.793px solid rgba(255, 255, 255, 0.50);
  background: rgba(255, 255, 255, 0.30);
  box-shadow: 0px -15.06037px 37.25459px 0px rgba(0, 168, 67, 0.19);
  backdrop-filter: blur(8.322834968566895px);
  
  ${isCurrent && (`
    opacity: 1;
  `)}
  
  @media (min-width: 375px) {
    min-width: 152px;
    height: 37px;
  }
  @media (min-width: 768px) {
    min-width: 340px;
    height: 50px;
    border-radius: 16px;
  }
  @media (min-width: 1400px) {
    min-width: 615px;
    height: 80px;
    border-radius: 25px;
  }
`);

const PlayButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  @media (min-width: 768px) {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 1400px) {
    width: 50px;
    height: 50px;
    margin-left: 15px;
  }
`;

const SongText = styled.div`
  text-align: left;
  display: flex;
  color: white;
  font-family: "SB Sans Display";
  font-size: 8px;
  font-weight: 300;
  @media (min-width: 375px) {
    font-size: 9px;
    margin-left: 9px;
  }
  @media (min-width: 768px) {
    font-size: 12px;
    font-weight: 300;
    margin-left: 10px;
  }
  @media (min-width: 1400px) {
    font-size: 24px;
    margin-left: 15px;
  }
`;

const CreatepageWrapper = styled.div`
  background: url(${publicImage("/bgcreate.svg")}) center no-repeat;
  position: relative;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
  background-color: black;
  @media (min-width: 375px) {
    min-height: 800px;
  }
  @media (min-width: 768px) {
    min-height: 1100px
  }
  @media (min-width: 1024px) {
    min-height: 950px;
    background-image: url(${publicImage("/bgcreate-desk.svg")});
  }
  @media (max-width: 374px) {
    min-height: 570px;
  }
`;
const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  
  padding: 20px 19px 0 20px;
  height: calc(100% - 20px);
  @media (min-width: 375px) {
    padding: 30px 30px 0;
    max-width: 375px;
    height: calc(100% - 30px);
  }
  @media (min-width: 768px) {
    padding: 40px 40px 0;
    max-width: 768px;
    height: 100%;
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
  }
  @media (min-width: 1400px) {
    padding: 50px 80px 0;
    max-width: 1400px;
  }
`;
const CreatePageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
  @media (min-width: 1400px) {
    margin-bottom: 50px;
  }
`;
const StyledLink = styled(Link)`
  display: flex;
  gap: 5px;
  text-decoration: none;
  cursor: pointer;
`;
const BackArrow = styled.img`
  display: block;
`;
const ReturnBackText = styled.span`
  color: #00d900;
  font-family: "SB Sans Display";
  line-height: 110%;
  font-size: 12px;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;
const HelpText = styled.span`
  cursor: pointer;
  font-family: "SB Sans Display";
  font-size: 12px;
  font-weight: 300;
  line-height: 110%;
  color: #00D900;
  //background: var(--Linear, linear-gradient(90deg, #198cfe 0%, #5f41d9 100%)) no-repeat;
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;
  //background-clip: text;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const TalyanContainer = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 38px;
  left: 4px;
  width: 91.5px;
  height: 91.5px;
  border-radius: 91.5px;
  background: url(${publicImage("/talyanneon.svg")}) center no-repeat;
  background-size: 150%;
  z-index: 1;

  &::before {
    user-select: none;
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50px;
    border-radius: 50%;
    background-color: #0E8BFF;
    filter: blur(50px);
    opacity: 0.5;
  }

  @media (min-width: 375px) {
    top: 70px;
    width: 124px;
    height: 124px;
    border-radius: 124px;
  }
  @media (min-width: 768px) {
    top: 49px;
    left: 24px;
    width: 206px;
    height: 206px;
    border-radius: 206px;
  }
  @media (min-width: 1400px) {
    top: 30px;
    left: 12px;
    width: 257px;
    height: 257px;
    border-radius: 257px;
  }
  @media (max-width: 767px) {
    cursor: default;
  }
`;
const TalyanImage = styled.img`
  width: 80%;
  z-index: 1;
  position: absolute;
  top: 30px;
  display: block;
  @media (max-width: 767px) {
    top: 15px;
  }
`;
const Inform = styled.img`
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  left: 5%;
  top: 5%;
  width: 18px;
  z-index: 4;
  backdrop-filter: blur(4px);
  @media (min-width: 375px) {
    width: 25px;
  }
  @media (min-width: 768px) {
    width: 41px;
  }
  @media (min-width: 1400px) {
    width: 50px;
  }
`;
const Talyan = styled.div`
  position: relative;
  border-radius: 50%;
  background: url(${publicImage("/talyanellipse.svg")});
  background-position: 50% 50%;
  background-size: fill;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const JaleykaContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 25px;
  left: 155px;
  width: 104px;
  height: 104px;
  border-radius: 104px;
  background: url(${publicImage("/jaleykaneon.svg")}) center no-repeat;
  background-size: 150%;

  &::before {
    user-select: none;
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50px;
    border-radius: 50%;
    background-color: #06C86B;
    filter: blur(50px);
    opacity: 0.5;
  }

  @media (min-width: 375px) {
    top: 46px;
    left: 170px;
    width: 144px;
    height: 144px;
    border-radius: 144px;
  }
  @media (min-width: 768px) {
    top: 46px;
    left: 404px;
    width: 235px;
    height: 235px;
    border-radius: 235px;
  }
  @media (min-width: 1024px) {
    left: 600px;
  }
  @media (min-width: 1400px) {
    top: 48px;
    left: 827px;
    width: 214px;
    height: 214px;
    border-radius: 214px;
  }
  @media (max-width: 767px) {
    cursor: default;
  }
`;
const JaleykaImage = styled.img`
  width: 70%;
  position: relative;
  left: 10px;
  display: block;
  bottom: 30px;
  @media (max-width: 767px) {
    bottom: 20px;
  }
`;
const Jaleyka = styled.div`
  background-size: fill;
  border-radius: 50%;
  background: url(${publicImage("/jaleykaellipse.svg")});
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const GusliContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 157px;
  left: 203px;
  width: 70px;
  height: 70px;
  border-radius: 70px;
  background: url(${publicImage("/guslineon.svg")}) center no-repeat;
  background-size: 150%;

  &::before {
    user-select: none;
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50px;
    border-radius: 50%;
    background-color: #38F3FF;
    filter: blur(50px);
    opacity: 0.5;
  }

  @media (min-width: 375px) {
    top: 224px;
    left: 214px;
    width: 96px;
    height: 96px;
    border-radius: 96px;
  }
  @media (min-width: 768px) {
    top: 327px;
    left: 493px;
    width: 158px;
    height: 158px;
    border-radius: 158px;
  }
  @media (min-width: 1024px) {
    left: 770px;
    top: 280px;
  }
  @media (min-width: 1400px) {
    top: 275px;
    left: 1003px;
    width: 199px;
    height: 199px;
    border-radius: 199px;
  }
  @media (max-width: 767px) {
    cursor: default;
  }
`;
const GusliImage = styled.img`
  width: 130%;
  position: absolute;
  bottom: 25px;
  left: 4px;
  display: block;
  @media (max-width: 767px) {
    bottom: 15px;
  }
`;
const Gusli = styled.div`
  background-size: 100%;
  border-radius: 50%;
  background: url(${publicImage("/gusliellipse.svg")});
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VarganContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 202px;
  left: 9px;
  width: 81px;
  height: 81px;
  border-radius: 81px;
  background: url(${publicImage("/varganneon.svg")}) center no-repeat;
  background-size: 150%;

  &::before {
    user-select: none;
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50px;
    border-radius: 50%;
    background-color: #2CDD98;
    filter: blur(50px);
    opacity: 0.5;
  }

  @media (min-width: 375px) {
    top: 309px;
    left: 5px;
    width: 113px;
    height: 113px;
    border-radius: 113px;
  }
  @media (min-width: 768px) {
    top: 379px;
    left: 5px;
    width: 172px;
    height: 172px;
    border-radius: 182px;
  }
  @media (min-width: 1024px) {
    left: 150px;
    top: 250px;
  }
  @media (min-width: 1400px) {
    top: 254px;
    left: 217px;
    width: 190px;
    height: 190px;
    border-radius: 203px;
  }
  @media (max-width: 767px) {
    cursor: default;
  }
`;
const VarganImage = styled.img`
  width: 70%;
  position: relative;
  top: -8px;
  right: -5px;
  display: block;
`;
const Vargan = styled.div`
  background-size: 100%;
  border-radius: 50%;
  background: url(${publicImage("/varganellipse.svg")});
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DungurContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 234px;
  left: 116px;
  width: 93px;
  height: 93px;
  border-radius: 93px;
  background: url(${publicImage("/dungurneon.svg")}) center no-repeat;
  background-size: 150%;

  &::before {
    user-select: none;
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50px;
    border-radius: 50%;
    background-color: #629E46;
    filter: blur(50px);
    opacity: 0.5;
  }

  @media (min-width: 375px) {
    top: 342px;
    left: 151px;
    width: 152px;
    height: 152px;
    border-radius: 152px;
  }
  @media (min-width: 768px) {
    top: 460px;
    left: 275px;
    width: 211px;
    height: 211px;
    border-radius: 211px;
  }
  @media (min-width: 1024px) {
    left: 300px;
    top: 70px;
  }
  @media (min-width: 1400px) {
    top: 95px;
    left: 398px;
    width: 187px;
    height: 187px;
    border-radius: 187px;
  }
  @media (max-width: 767px) {
    cursor: default;
  }
`;
const DungurImage = styled.img`
  width: 70%;
  position: relative;
  bottom: 5px;
  left: 5px;
  display: block;
`;
const Dungur = styled.div`
  background-size: 100%;
  border-radius: 50%;
  background: url(${publicImage("/dungurellipse.svg")});
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PandurContainer = styled.div`
  position: absolute;
  cursor: pointer;
  top: 128px;
  left: 90px;
  width: 93px;
  height: 93px;
  border-radius: 50%;
  background: url(${publicImage("/pandurneon.svg")}) center no-repeat;
  background-size: 150%;

  &::before {
    user-select: none;
    pointer-events: none;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50px;
    border-radius: 50%;
    background-color: #A7FF36;
    filter: blur(50px);
    opacity: 0.5;
  }

  @media (min-width: 375px) {
    top: 200px;
    left: 70px;
    width: 108px;
    height: 108px;
    border-radius: 108px;
  }
  @media (min-width: 768px) {
    top: 240px;
    left: 232px;
    width: 180px;
    height: 180px;
    border-radius: 180px;
  }
  @media (min-width: 1024px) {
    left: 470px;
    top: 250px;
  }
  @media (min-width: 1400px) {
    top: 201px;
    left: 617px;
    width: 190px;
    height: 190px;
    border-radius: 209px;
  }
  @media (max-width: 767px) {
    cursor: default;
  }
`;
const PandurImage = styled.img`
  width: 35%;
  position: relative;
  bottom: 40%;
  right: -5px;
  display: block;
`;
const Pandur = styled.div`
  background-size: 100%;
  border-radius: 50%;
  background: url(${publicImage("/pandurellipse.svg")});
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FinishButton = styled.button`
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 271px;
  bottom: 27px;
  height: 45px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  font-family: "SB Sans Display";
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: url(${publicImage("/btnellipse.svg")}) center no-repeat;
  background-size: fill;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  @media (min-width: 375px) {
    width: 315px;
    left: 50%;
    height: 46px;
    bottom: 58px;
  }
  @media (min-width: 768px) {
    width: 400px;
    height: 60px;
    font-size: 24px;
    left: 50%;
    bottom: 40px;
  }
  @media (min-width: 1400px) {
    bottom: 50px;
  }
`;

const InstrumentsContainer = styled.div`
  position: relative;
  width: 280px;
  margin: 0 auto;
  
  @media (min-width: 375px) {
    width: 315px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    width: 688px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    width: 944px;
  }
  @media (min-width: 1400px) {
    width: 1240px;
    margin: 0 auto;
  }
  @media (max-width: 767px) and (min-width: 375px) {
    cursor: default;
    margin-top: max(calc(22vh - 150px), 0px);
  }
`;

const PlayPauseButtonIcons = styled.img`
  width: 20px;
  height: 20px;
  @media (min-width: 768px) {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 1400px) {
    width: 50px;
    height: 50px;
  }
`;

const TalyanSign = styled.img`
  position: absolute;
  z-index: 3;
  bottom: 5px;
  width: 100%;
`;

const JaleykaSign = styled.img`
  position: absolute;
  bottom: 5px;
  width: 100%;
  z-index: 3;
`;
const PandurSign = styled.img`
  position: absolute;
  bottom: 5px;
  width: 100%;
  z-index: 3;
`;
const DungurSign = styled.img`
  position: absolute;
  bottom: 5px;
  width: 100%;
  z-index: 3;
`;
const GusliSign = styled.img`
  position: absolute;
  bottom: 5px;
  width: 100%;
  z-index: 3;
`;
const VarganSign = styled.img`
  position: absolute;
  bottom: 5px;
  width: 100%;
  z-index: 3;
`;

const Animation = styled(Lottie)`
  position: absolute;
  width: 180%;
  z-index: 1;
`;
