import React, {useEffect} from 'react';
import HistoryPageSection1 from "./HistoryPageSection1";
import HistoryPageSection2 from "./HistoryPageSection2";
import styled from "styled-components";

const History = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <AppWrapper>
      <HistoryPageSection1 />
      <HistoryPageSection2 />
    </AppWrapper>
  );
};

export default History;

const AppWrapper = styled.div`
  min-width: 320px;
  margin: 0 auto;
  overflow-x: hidden;
  over-flow: hidden;
  background-color: black;
`;
