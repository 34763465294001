import React, { useRef, useEffect } from "react";
// import VideoBG from "../../../shared/video.mp4";
import styled from "styled-components";

export default function BGVideo() {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
    // videoRef.current.play();
  });

  return (
    <VideoContainer>
      <Video
        src="https://sber.cdnvideo.ru/common/video/podlojka.mp4"
        ref={videoRef}
        loop
        autoPlay
        muted
        playsInline
      />
    </VideoContainer>
  );
}

const Video = styled.video`
  object-fit: cover;
  object-position: center;
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const VideoContainer = styled.div`
  background-color: black;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;
