const popupData = {
  talyan: {
    text: '<b>Тальян-гармун, или тальянка,&nbsp;&mdash;</b> татарский ручной клавишно-пневматический музыкальный инструмент. В&nbsp;отличие от&nbsp;гармони клавиатура тальянки имеет меньшее количество кнопок: 12-15&nbsp;на левой стороне и&nbsp;3-6&nbsp;на правой. Известна с&nbsp;конца 19&nbsp;в. Тальян-гармун и&nbsp;уникальная манера игры на&nbsp;ней стали брендом татарской национальной культуры.',
    video: 'https://sber.cdnvideo.ru/common/video/talyanwindow.mp4',
  },
  jaleika: {
    text: '<b>Жалейка&nbsp</b>&mdash; старинный славянский музыкальный инструмент. Он&nbsp;представляет собой небольшую трубку&nbsp;&mdash; около 10-20&nbsp;см в&nbsp;длину, из&nbsp;дерева или тростника. В&nbsp;боковых стенках трубки есть несколько отверстий, зажимая которые, можно извлекать звуки различной высоты.',
    video: 'https://sber.cdnvideo.ru/common/video/jaleikawindow.mp4',
  },
  pandur: {
    text: '<b>Пандур</b>&nbsp;&mdash; двухструнный музыкальный инструмент коренного населения Кавказских республик. Он&nbsp;выскабливается из&nbsp;единого куска дерева, высверливаются отверстия и&nbsp;натягиваются только две струны. При изготовлении инструмента используют липу и&nbsp;кишки молодого козла. В&nbsp;Дагестане регулярно проводятся фестивали исполнителей музыки на&nbsp;пандуре.',
    video: 'https://sber.cdnvideo.ru/common/video/pandurwindow.mp4',
  },
  gusli: {
    text: '<b>Гусли</b>&nbsp;&mdash; один из&nbsp;древнейших щипковых музыкальных инструментов, существовавших на&nbsp;Руси. Гусли кладут на&nbsp;колени или располагают на&nbsp;специальных подставках. Инструмент представляют собой конструкцию с&nbsp;веером струн на&nbsp;струнодержателе, резонатором и&nbsp;колковым рядом. Игрой на&nbsp;гуслях в&nbsp;совершенстве владели почти все богатыри из&nbsp;древних русских былин.',
    video: 'https://sber.cdnvideo.ru/common/video/gusliwindow.mp4',
  },
  vargan: {
    text: '<b>Варган</b> относится к&nbsp;разновидности самозвучащих язычковых музыкальных инструментов. Язычок, как правило, приводит в&nbsp;движение палец музыканта. Научиться извлекать звуки на&nbsp;варгане можно за&nbsp;несколько минут, но&nbsp;виртуозное исполнение на&nbsp;инструменте доступно далеко не&nbsp;каждому. Распространён среди коренного населения Севера России.',
    video: 'https://sber.cdnvideo.ru/common/video/varganwindow.mp4',
  },
  dungur: {
    text: '<b>Дунгур</b>&nbsp;&mdash; это разновидность бубна, обтянутого с&nbsp;одной стороны кожаной мембраной, неизменный шаманский атрибут. На&nbsp;дунгур могут быть нанесены рисунки, символизирующие верования шамана. У&nbsp;дунгура также есть колотушка, или орба. С&nbsp;их&nbsp;помощью проводятся ритуалы и&nbsp;обряды в&nbsp;республиках: Бурятии, Тыве, Якутии, Хакасии и&nbsp;других регионах России.',
    video: 'https://sber.cdnvideo.ru/common/video/dungurwindow.mp4',
  }
}

export default popupData;
