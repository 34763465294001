import styled from "styled-components";

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transform: scale(${({ open }) => open ? 0.5 : 1 });
  margin-right: ${({ open }) => open ? '12px' : 0 };
  z-index: 5;
  height: 8px;
  transition: 0.2s;
  transform-origin: center;
  @media (min-width: 375px) {
    top: 27px;
    right: 31px;
  }
  @media (min-width: 768px) {
    height: 12px;
  }
  @media (min-width: 1400px) {
    display: none;
  }

  :first-child {
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
  }

  :last-child {
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
  }

  div {
    width: 22px;
    height: 4px;
    background-color: white;
    transition: all 0.3s linear;
    position: relative;
    z-index: 99;
    transform-origin: 8px;
    @media (min-width: 768px) {
      width: 30px;
      transform-origin: 11px;
    }
  }
`;
