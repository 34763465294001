import React from 'react';
import styled from "styled-components";
import Flex from "../../../shared/Flex";
import BGVideo from "./BGVideo";
import PlayVideoComponent from "./PlayVideoComponent";
import Header from "./Header";
import { publicImage } from "../../../shared/util";

import ListenImg from '../assets/listen.svg'
import DreamImg from '../assets/dream.svg'
import CreateImg from '../assets/create.svg'

const WelcomePageSection1 = ({ toggleOpen }) => {
  const handleArrowClick = () => {
    const element = document.getElementById("section2");
    element.scrollIntoView({ behavior: "smooth", block: 'nearest' });
  };

  return (
    <Container>
      <BGVideoContainer>
        <BGVideo />
      </BGVideoContainer>
      <Section1BG>
        <Header toggleOpen={toggleOpen} />
        <FlexContainer>
          <Flex justify="start" gap="0">
            <StyledText1
              src={ListenImg}
              alt="Слушай"
            />
            <ButtonWrapper onClick={toggleOpen}>
              <PlayVideoComponent onClick={toggleOpen} />
            </ButtonWrapper>
          </Flex>
          <StyledText2
            src={DreamImg}
            alt="Вдохновляйся"
          />
          <StyledText3
            src={CreateImg}
            alt="Создавай"
          />
        </FlexContainer>
        <MoreInfoContainer>
          <Flex direction="column" gap="15px" align="center">
            <InformArticle>Узнайте больше</InformArticle>
            <ArrowImage
              onClick={handleArrowClick}
              src={publicImage("/arrow.svg")}
              alt="Узнайте больше"
            />
          </Flex>
        </MoreInfoContainer>
      </Section1BG>
    </Container>
  );
};

export default WelcomePageSection1;

const ButtonWrapper = styled.div`
  //@media (min-width: 1024px) and (max-width: 1439px) {
  //  margin-bottom: 24px;
  //}
`;

const StyledText1 = styled.img`
  display: block;
  width: 49.4vw;
  position: relative;
  z-index: 1;
  margin-left: -1px;
  @media (min-width: 768px) {
    width: 35.5vw;
  }
  @media (min-width: 1024px) {
    width: 29vw;
  }
  @media (min-width: 1400px) {
    width: 25.77vw;
  }
`;

const StyledText2 = styled.img`
  display: block;
  width: 69.1vw;
  margin-left: 11vw;
  z-index: 1;
  margin-top: -20px;
  @media (min-width: 768px) {
    width: 51.6vw;
    margin-left: 15vw;
  }
  @media (min-width: 1024px) {
    width: 45vw;
  }
  @media (min-width: 1400px) {
    width: 37.5vw;
    margin-left: 11vw;
  }
`;

const StyledText3 = styled.img`
  display: block;
  width: 51.6vw;
  margin-left: 38vw;
  margin-top: -30px;
  @media (min-width: 768px) {
    width: 40vw;
  }
  @media (min-width: 1024px) {
    width: 35.9vw;
    margin-left: 42.5vw;
  }
  @media (min-width: 1400px) {
    width: 26.15vw;
    margin-left: 31vw;
  }
`;

const MoreInfoContainer = styled.div`
  //@media (min-width: 1440px) {
  //  padding-top: 32px;
  //  margin-top: auto;
  //}
`;

const InformArticle = styled.span`
  z-index: 1;
  color: #8dff57;
  text-align: center;
  font-family: "SB Sans Display";
  font-size: 12px;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const ArrowImage = styled.img`
  cursor: pointer;
  z-index: 1;
  display: block;
`;

const Container = styled.div`
  //height: 100vh;
`;

const BGVideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 0;
`

const Section1BG = styled.div`
  background: linear-gradient(
          to top,
          black 10%,
          transparent,
          transparent,
          transparent
  ),
  linear-gradient(to bottom, black 3%, transparent, transparent, transparent),
  no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  right: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
`;

const FlexContainer = styled.div`
  align-self: center;
  margin: auto 0 52px ;
  @media (min-width: 1024px) {
    margin-bottom: auto;
  }
`;
