import styled from "styled-components";
import { publicImage } from "./util";

const LargeButton = (props) => {
  return <ButtonLarge {...props} />;
};

export default LargeButton;

export const ButtonLarge = styled.button`
  width: 272px;
  height: 45px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  font-family: "SB Sans Display";
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: url(${publicImage("/btnellipse.svg")}) center no-repeat;
  background-size: fill;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(3px);
  color: white;
  @media (min-width: 375px) {
    margin-top: 64px;
  }
  @media (min-width: 768px) {
    margin-top: 40px;
    width: 400px;
    font-size: 24px;
    height: 60px;
  }
  @media (min-width: 1400px) {
    margin-top: 58px;
  }
`;
