import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import ResultPageSection1 from "./components/ResultPageSection1";
import ResultPageSection2 from "./components/ResultPageSection2";
import ResultPageSection3 from "./components/ResultPageSection3";
import VideoModal from "../../shared/VideoModal";
import {publicImage} from "../../shared/util";

export default function ResultPage() {
  const [open, setOpen] = useState(false);
  const handleToggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <AppWrapper>
      <ResultPageSection1Background>
        <ResultPageSection1 setOpen={setOpen}/>
      </ResultPageSection1Background>
      <ResultPageSection2 setOpen={setOpen}/>
      <ResultPageSection3 />
      <VideoModal open={open} toggleOpen={handleToggleOpen} />
    </AppWrapper>
  );
}

const ResultPageSection1Background = styled.div`
  background:
          linear-gradient(0deg, black 0%, rgba(0,0,0,0) 85.42%),
          url("${publicImage('/result-bg.png')}") center no-repeat;
  background-size: cover;
  background-color: black;
`;

const AppWrapper = styled.div`
  min-width: 320px;
  margin: 0 auto;
  overflow-x: hidden;
  over-flow: hidden;
`;
