import React, {useEffect, useState} from 'react';
import WelcomePageSection1 from "./components/WelcomePageSection1";
import WelcomePageSection2 from "./components/WelcomePageSection2";
import styled from "styled-components";
import VideoModal from "../../shared/VideoModal";

export default function WelcomePage() {
  const [open, setOpen] = useState(false);
  const handleToggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <AppWrapper>
      <SectionOneBackground>
        <WelcomePageSection1 toggleOpen={handleToggleOpen} />
      </SectionOneBackground>
      <SectionTwoBackground>
        <WelcomePageSection2 />
      </SectionTwoBackground>
      <VideoModal open={open} toggleOpen={handleToggleOpen} />
    </AppWrapper>
  );
}

const SectionOneBackground = styled.div`
  background: linear-gradient(
      to top,
      black 10%,
      transparent,
      transparent,
      transparent
    ),
    linear-gradient(to bottom, black 3%, transparent, transparent, transparent),
    no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const SectionTwoBackground = styled.div`
  background-color: black;
`;

const AppWrapper = styled.div`
  min-width: 320px;
  margin: 0 auto;
  overflow-x: hidden;
  over-flow: hidden;
`;
