import React, { useState } from "react";
import BurgerButton from "./BurgerMenuButton";
import Menu from "./Menu";

const BurgerMenu = ({ toggleOpen }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <BurgerButton open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} toggleOpen={toggleOpen} />
    </>
  );
};

export default BurgerMenu;
