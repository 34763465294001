import React, { useMemo } from "react";
import styled from "styled-components";

function makeLeadingZero(number) {
  return number < 10 ? `0${number.toFixed(0)}` : String(number.toFixed(0));
}

function toTimeString(secondsCount) {
  if (Number.isNaN(secondsCount)) return '00:00';
  const minutes = Math.floor(secondsCount / 60);
  const seconds = secondsCount - minutes * 60;
  return `${makeLeadingZero(minutes)}:${makeLeadingZero(seconds)}`;
}

const PlayerProgressbar = ({ position = 0, duration = 35 }) => {
  const containerStyles = {
    height: 5,
    width: "100%",
    maxWidth: "682px",
    backgroundColor: "#333F48",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${position * 100}%`,
    backgroundColor: "white",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const startDur = useMemo(
    () => toTimeString(Math.round(duration * position)),
    [duration, position]
  );

  const leftDur = useMemo(() => toTimeString(duration), [duration]);

  return (
    <>
      <ProgressFlex>
        <PassedDur position={position}>{startDur}</PassedDur>

        <PassedDur position={position}>{leftDur}</PassedDur>
      </ProgressFlex>
      <div style={containerStyles}>
        <div style={fillerStyles}></div>
      </div>
    </>
  );
};

export default PlayerProgressbar;

const PassedDur = styled.span`
  color: white;
  font-family: SB Sans Display;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.8;
`;

const ProgressFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 280px;
  margin-bottom: 3px;
  @media(min-width: 375px){
    width: 315px;
  }
  @media(min-width: 768px){
    width: 682px;
  }
`;
