import React from "react";
import styled from "styled-components";
import Footer from "../WelcomePage/components/Footer";

const HistoryPageSection2 = () => {
  return (
    <>
      <HistoryPageSection2Wrapper>
        <Video src="https://sber.cdnvideo.ru/common/video/volna.mp4" autoPlay muted playsInline controls={false} loop style={{
          width: '100%',
        }} />
      </HistoryPageSection2Wrapper>
      <Footer />
    </>
  );
};

export default HistoryPageSection2;

const HistoryPageSection2Wrapper = styled.div`
  background-color: black;
  z-index: 1;
`;

const Video = styled.video`
  position: relative;
  z-index: 2;
`
