import React, {useEffect} from 'react';
import styled from "styled-components";
import { publicImage } from "../../shared/util";

const Page404 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Flex404>
      <Container3>
        <Title404>Ошибка 404</Title404>
        <Article404>
          <span>Такой страницы больше нет</span>
          <span> или она никогда не существовала</span>
        </Article404>
      </Container3>
      <Container1>
        <GreenEllipse src={publicImage("/greenellipse.svg")} alt="" />
      </Container1>
      <Container2>
        <BlueEllipse src={publicImage("/blueellipse.svg")} alt="" />
      </Container2>
    </Flex404>
  );
};

export default Page404;

const Flex404 = styled.div`
  position: absolute;
  display: flex;
  background: linear-gradient(180deg, #000 9.06%, #333f48 100%);
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  overflow: hidden;
  gap: 24px;
  @media (min-width: 768px) {
    gap: 40px;
  }
`;

const Title404 = styled.div`
  color: white;
  text-align: center;
  font-family: SB Sans Text;
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

const Article404 = styled.div`
  color: white;
  text-align: center;
  font-family: SB Sans Text;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @media (min-width: 768px) {
    margin-top: 40px;
    font-size: 20px;
  }
`;

const GreenEllipse = styled.img`
  width: 100%;
  height: 100%;
`;

const BlueEllipse = styled.img`
  width: 100%;
  height: 100%;
`;

const Container1 = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const Container2 = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
const Container3 = styled.div`
  margin: 250px 16px 225px;
  @media (min-width: 375px) {
    margin: 283px 16px 260px;
  }
  @media (min-width: 768px) {
    margin: 413px 56px 397px;
  }
`;
